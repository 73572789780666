import { gql } from 'apollo-boost';

const GET_INFO_RES = gql`
  query parkAssetDetails(
    $cursorPagination: CursorPaginationCriteria
    $assetCriteria: AssetCriteria
    $parkId: Mixed!
  ) {
    allParks(filters: [{column: "active", operator: ">=", value: "0"}, {column: "id", operator: "=", value: $parkId}]) {
      id
      name
       ownersAreaResources(cursorPagination: $cursorPagination, assetCriteria: $assetCriteria) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            category {
              id
              name
            }
            asset {
              id
              title
              description
              path
              size
              createdAt
              media {
                id
                path
                alternateText
              }
              meta {
                assetCategoryId
                order
              }
            }
          }
        }
      }
    }
  }
`;

// This is intentionally outside of the GET_INFO_RES block
export const GET_ASSET_CATEGORIES = gql`
  {
    allAssetCategories {
      id
      name
    }
  }
`;

export default GET_INFO_RES;
