import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from '@parkholidays/legacy_components'
import IconWithDirection from '@parkholidays/legacy_components/dist/icon/IconWithDirection'
import Text from '@parkholidays/legacy_components/dist/text/Text'

import styles from './styles'

const propTypes = {
  pageLabel: PropTypes.string,
  previousDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  onHandlePreviousClick: PropTypes.func,
  onHandleNextClick: PropTypes.func,
}

const defaultProps = {
  previousDisabled: false,
  nextDisabled: false,
}

const PaginationBar = ({
  pageLabel,
  previousDisabled,
  nextDisabled,
  onHandlePreviousClick,
  onHandleNextClick,
  ...otherProps
}) => {
  const [css, theme] = useStyletron()
  const {
    paginationBarStyles,
    prevButtonStyles,
    nextButtonStyles,
    pageLabelWrapper,
  } = styles({
    theme,
  })

  return (
    <div className={css(paginationBarStyles)} {...otherProps}>
      <button
        className={css(prevButtonStyles)}
        aria-label="Previous Page"
        aria-disabled={previousDisabled}
        disabled={previousDisabled}
        onClick={onHandlePreviousClick}
      >
        <IconWithDirection direction="left" width="18" height="18" />
      </button>
      <div className={css(pageLabelWrapper)}>
        <Text weight="bolder">{pageLabel}</Text>
      </div>
      <button
        className={css(nextButtonStyles)}
        aria-label="Next Page"
        aria-disabled={nextDisabled}
        disabled={nextDisabled}
        onClick={onHandleNextClick}
      >
        <IconWithDirection direction="right" width="18" height="18" />
      </button>
    </div>
  )
}

PaginationBar.propTypes = propTypes
PaginationBar.defaultProps = defaultProps

export default PaginationBar
