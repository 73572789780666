import PropTypes from 'prop-types'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import Theme from '../../context/theme'

import OffCanvas from '../../components/ui/off-canvas'
import Nav, { NavLink } from './side-menu.styled'

import sideMenuItems from './menuItems'
import { myUrl } from 'src/constants'

const styles = { fontFamily: 'Raleway,Helvetica,Arial,sans-serif' }
const sideMenuProptypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

/**
 * Side Menu
 *
 * @param {*} { open, ...otherProps }
 * @returns
 */
const SideMenu = ({ open, onClose, ...otherProps }) => {
  const { theme } = React.useContext(Theme)

  const { location } = otherProps

  const isActiveLink = path => location.pathname === path
  const navLinkProps = (link, external) =>
    external ? { target: '_blank', href: link } : { to: link }

  const useMenuItems = sideMenuItems;

  return (
    <OffCanvas open={open} onClose={onClose} $style={styles}>
      <Nav>
        {useMenuItems.concat([{
    label: 'My Account',
    link: myUrl(theme.isPl2k),
    external: true,
  },
  {
    label: 'Logout',
    link: '/logout',
    external: false,
  }]).map(({ label, link, external }) => (
          <NavLink
            key={link}
            $as={external ? 'a' : Link}
            $active={isActiveLink(link)}
            $theme={theme}
            {...navLinkProps(link, external)}
            onClick={onClose}
          >
            {label}
          </NavLink>
        ))}
      </Nav>
    </OffCanvas>
  )
}

SideMenu.propTypes = sideMenuProptypes
SideMenu.defaultProps = {
  open: false,
}

export default withRouter(SideMenu)
