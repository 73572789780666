import React from 'react';
import { Helmet } from 'react-helmet-async';

const ParkHolidaysHead = () => {
  const hostIsParkHolidaysProduction = window.location.hostname === 'owners.parkholidays.com';
  return (
    <Helmet>
      <title>Owners Area</title>
      <link rel="canonical" href="https://owners.parkholidays.com/" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/logo192.png" />
      <link rel="" href="" />
      {/* Onetrust cookies consent script*/}
      {hostIsParkHolidaysProduction ? (
        <>
          <script
            type="text/javascript"
            src="https://cdn.cookielaw.org/consent/018e527b-4f5d-7f4b-8228-898f83226371/OtAutoBlock.js"
          ></script>
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script="018e527b-4f5d-7f4b-8228-898f83226371"
          ></script>
        </>
      ) : (
        <>
          <script
            type="text/javascript"
            src="https://cdn.cookielaw.org/consent/018e527b-4f5d-7f4b-8228-898f83226371-test/OtAutoBlock.js"
          ></script>
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script="018e527b-4f5d-7f4b-8228-898f83226371-test"
          ></script>
        </>
      )}
      <script type="text/javascript">{`function OptanonWrapper(){}`}
      </script>
    </Helmet>
  );
};

export default ParkHolidaysHead;
