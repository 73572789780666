
/**
 * Deep Merge
 * Method to merge multiple objects recursively.
 *
 * @param {*} target
 * @param {*} sources
 * @returns
 */
const deepMerge = (...sources) => {
  let target = {};

  sources.forEach(source => {
    Object.keys(source).forEach(key => {
      if (Array.isArray(target[key])) target[key] = [].concat(target[key]).concat(source[key]);
      else if (typeof target[key] === 'object' && target[key] !== null) target[key] = deepMerge(target[key], source[key]);
      else target[key] = source[key];
    });
  });

  return target;
};

export default deepMerge;
