import React from 'react'
import { useApolloClient } from '@apollo/react-hooks'

import { JSDateToGraphQL } from '../../helpers/formatDate'
import Container from '../../components/ui/container'
import Icon from '../../components/ui/icon'

import Theme from '../../context/theme'
import ParkContext from '../../context/park'

import Typography from '../../components/ui/typography'
import ParkSelectToolbar from '../../components/parkSelectToolbar'
import Loader from '../../components/ui/loading'

import { SpecialOfferGrid, SpecialOfferCard } from './components'

// import { useParkId } from '../../context/park';

import GET_ALL_SPECIAL_OFFERS from './graphql'

const SpecialOffers = React.memo(() => {
  const { park } = React.useContext(ParkContext)
  // const { parkId } = useParkId();
  const client = useApolloClient()
  const { theme } = React.useContext(Theme)
  const [specialOffers, setSpecialOffers] = React.useState([])
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    let mounted = true

    const getOffers = async () => {
      if (mounted) setLoading(true)

      try {
        const { data } = await client.query({
          query: GET_ALL_SPECIAL_OFFERS,
          variables: {
            criteria: {
              parkId: park.id,
            },
            filters: [
              {
                column: 'tenantId',
                operator: '=',
                value: 2,
              },
              {
                column: 'publishAt',
                operator: '<',
                value: JSDateToGraphQL(new Date()),
              },
              {
                column: 'expiresAt',
                operator: '>=',
                value: JSDateToGraphQL(new Date()),
              },
            ],
          },
        })

        // Order by order
        data.allOwnershipSpecialOffers.sort((a, b) => {
          if (a.order < b.order) {
            return 1
          }
          if (a.order > b.order) {
            return -1
          }
          return 0
        })

        if (mounted) setSpecialOffers(data.allOwnershipSpecialOffers)
      } catch (err) {
        if (mounted) setError('Error')
      }

      if (mounted) setLoading(false)
    }
    if (mounted) getOffers()

    return () => {
      mounted = false
    }
  }, [client, park.id])

  return (
    <>
      <ParkSelectToolbar />
      <Container $theme={theme} $style={{ marginTop: `${theme.spacing(2)}px` }}>
        <Typography
          as="h1"
          weight="bold"
          beforeEnhancer={
            <Icon
              name="oa-specialoffers"
              size={24}
              style={{ marginRight: theme.spacing(1) }}
            />
          }
        >
          News &amp; Special offers
        </Typography>

        {loading && <Loader />}

        {error && (
          <p>
            Unfortunately we couldn&apos;t load the special offers, please try
            again later.
          </p>
        )}

        {specialOffers && specialOffers.length > 0 && (
          <SpecialOfferGrid>
            {specialOffers.map((specialOffer, i) => (
              <SpecialOfferCard
                key={`specialOffer${i + 1}`}
                {...{ specialOffer }}
              />
            ))}
          </SpecialOfferGrid>
        )}
      </Container>
    </>
  )
})

export default SpecialOffers
