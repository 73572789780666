import React from 'react';
import PropTypes from 'prop-types';
import { useStyletron } from '@parkholidays/legacy_components';

import Text from '@parkholidays/legacy_components/dist/text/Text';
import Image from '@parkholidays/legacy_components/dist/image/Image';
import OwnershipButton from '@parkholidays/legacy_components/dist/button/OwnershipButton';
import { useContentStackImageSrc } from '../../../../hooks/useContentStackImageSrc';

import { ASSETS_PREFIX } from '../../../../config';

const propTypes = {
  media: PropTypes.shape({
    src: PropTypes.string,
    alternateText: PropTypes.string,
  }),
  title: PropTypes.string,
  page: PropTypes.shape({
    url: PropTypes.string,
  }),
  shortDescription: PropTypes.string,
};

const SpecialOffer = ({
  specialOffer: { media, title, page, shortDescription },
}) => {
  const [css, theme] = useStyletron();
  const contentStackImageSrc = useContentStackImageSrc(media);

  return (
    <div
      className={css({
        boxSizing: 'border-box',
        display: 'flex',
        width: '100%',
        paddingTop: '0.5em',
        paddingRight: '0.5em',
        paddingBottom: '0.5em',
        paddingLeft: '0.5em',
        flexDirection: 'column',
        '@media all and (min-width: 40em)': {
          width: '50%',
        },
        '@media all and (min-width: 60em)': {
          width: '33.33%',
        },
      })}
    >
      <div
        className={css({
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 auto',
          width: '100%',
          borderRadius: '4px',
          backgroundColor: theme.palette.common.white,
          boxShadow: theme.lighting.shadow500,
          overflow: 'hidden',
        })}
      >
        {media && media.path && (
          <Image
            src={ contentStackImageSrc ?? `${ASSETS_PREFIX}assets/${media.path}`}
            alt={`${title} Image`}
            noLoading
          />
        )}
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
            paddingTop: '1rem',
            paddingRight: '1rem',
            paddingBottom: '1rem',
            paddingLeft: '1rem',
            textAlign: 'center',
          })}
        >
          <div className={css({ marginBottom: '1rem' })}>
            <Text weight="bolder" $style={{ marginBottom: '0.5rem' }}>
              {title}
            </Text>
            <Text>{shortDescription}</Text>
          </div>
          <div>
            <OwnershipButton
              ariaLabel='view offer'
              href={`/special-offers${page.url}`}
              $style={{ width: '100%' }}
            >
              View Offer
            </OwnershipButton>
          </div>
        </div>
      </div>
    </div>
  );
};
SpecialOffer.propTypes = propTypes;

export default SpecialOffer;
