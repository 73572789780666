import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@parkholidays/legacy_components'
import Text from '@parkholidays/legacy_components/dist/text/Text'

const propTypes = {
  category: PropTypes.string.isRequired,
  addedDate: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}

const ResourceHeader = ({ category, addedDate, className }) =>(
  <header className={className}>
    <Text weight="bolder" color="pl2kBlack">{category}</Text>
    <Text color="pl2kBlack">Uploaded {addedDate}</Text>
  </header>
)

ResourceHeader.propTypes = propTypes

export default styled(ResourceHeader, ({ $theme }) => ({
  boxSizing: 'border-box',
  // display: 'flex',
  // justifyContent: 'space-between',
  paddingTop: '1rem',
  paddingRight: '1rem',
  paddingBottom: '1rem',
  paddingLeft: '1rem',
  backgroundColor: $theme.palette.corporatePale,
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
}))
