/*
  DO NOT MODIFY THIS FILE
  -- unstyled button
*/
import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '../styles'

export const KIND = {
  button: 'button',
  submit: 'submit',
}

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.keys(KIND)),
  onClick: PropTypes.func,
  buttonRef: PropTypes.func,
}

const defaultProps = {
  className: null,
  children: null,
  type: KIND.button,
  onClick: null,
  buttonRef: null,
}

const Button = ({
  className,
  children,
  type,
  onClick,
  buttonRef,
  ...props
}) => {
  return React.createElement(
    'button',
    {
      type: type === undefined ? KIND.button : type,
      onClick,
      ref: buttonRef,
      className,
      ...props,
    },
    children,
  )
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default styled(Button, ({ fullWidth }) => ({
  cursor: 'pointer',
  display: 'inline-block',
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  position: 'relative',
  textAlign: 'center',
  textDecoration: 'none',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'black',
  width: 'auto',
  paddingTop: 4,
  paddingBottom: 4,
  paddingLeft: 8,
  paddingRight: 8,
  backgroundColor: 'lightgrey',
  color: 'black',
  fontSize: 14,
  fontFamily: 'inherit',
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.3,
  },
  ...(fullWidth && {
    width: '100%',
  }),
}))
