import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from '@parkholidays/legacy_components'

const styles = {
  descriptionContainer: {
    padding: '1rem',
  },
}

const propTypes = {
  description: PropTypes.string.isRequired,
}

const Details = ({ description }) => {
  const [css] = useStyletron()

  return <div className={css(styles.descriptionContainer)}>{description}</div>
}

Details.propTypes = propTypes

export default Details
