import React, { useContext, useState } from 'react'

import Container from '../ui/container'
import Theme from '../../context/theme'
import ParkContext from '../../context/park'

import { Park, DetailedPark } from './components'
import {
  ParkWrapper,
  ParkDropdown,
  ParkDropdownContent,
} from './park-select-toolbar.styled'

const ParkSelectToolbar = React.memo(() => {
  const { theme } = useContext(Theme)
  const { isLoading, allParks, park, setPark } = useContext(ParkContext)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  if (!isLoading && allParks.length > 1) {
    return (
      <ParkWrapper
        $theme={theme}
        role="button"
        aria-label="Switch park content"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        tabIndex="0"
      >
        {/* <span>Select a park</span> */}
        <Container>
          <Park
            park={
              allParks.find(p => p.id === park.id.toString()) ||
              allParks.sort((a, b) => (a.name > b.name ? 1 : -1))[0]
            }
            open={dropdownOpen}
          />
          <ParkDropdown>
            <ParkDropdownContent $theme={theme} $open={dropdownOpen}>
              {allParks
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(p => (
                  <DetailedPark
                    key={p.id}
                    park={p}
                    onClick={() => setPark(p.id)}
                  />
                ))}
            </ParkDropdownContent>
          </ParkDropdown>
        </Container>
      </ParkWrapper>
    )
  }

  return null
})

export default ParkSelectToolbar
