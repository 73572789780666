import { gql } from 'apollo-boost'

export default gql`
  query eventBySlug($slug: String!) {
    eventBySlug(slug: $slug) {
      id
      title
      description
      location
      startDate
      endDate
      slug
      pinned
      cancelled
      parkId
      type {
        id
        name
      }
      category {
        id
        name
        media {
          id
          path
        }
      }
      eventTypeId
      parkId
      deletedAt
      media {
        id
        alternateText
        path
      }
    }
  }
`
