import React from 'react'
import PropTypes from 'prop-types'
import { withStyle } from '../styles'
import BaseButton from './BaseButton'

export default withStyle(BaseButton, ({ $theme }) => ({
  borderRadius: '4px',
  backgroundColor: $theme.palette.common.white,
  color: $theme.palette.common.gray900,
  ':hover': {
    backgroundColor: $theme.palette.common.gray50,
  },
}))
