import React from 'react'
import PropTypes from 'prop-types'
import { withStyle } from '../styles'
import BaseButton from './BaseButton'

export default withStyle(BaseButton, ({ $theme }) => ({
  borderRadius: '4px',
  backgroundColor: $theme.palette.ownership,
  color: $theme.palette.common.white,
  ':hover': {
    backgroundColor: $theme.palette.ownershipDark,
    color: $theme.palette.corporate40,
  },
}))
