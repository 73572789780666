import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from '@parkholidays/legacy_components'

import Anchor from '../../../components/ui/anchor'
import Icon from '../../../components/ui/icon'
import Typography from '../../../components/ui/typography'
import Image from '@parkholidays/legacy_components/dist/image/Image'
import Text from '@parkholidays/legacy_components/dist/text/Text'
import { useContentStackImageSrc } from '../../../hooks/useContentStackImageSrc';

const propTypes = {
  offer: PropTypes.shape({
    media: PropTypes.shape({
      path: PropTypes.string,
    }),
    title: PropTypes.string,
    shortDescription: PropTypes.string,
    page: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
}

const SpecialOffer = ({ offer: { media, title, shortDescription, page } }) => {
  const [css, theme] = useStyletron()
  const contentStackImageSrc = useContentStackImageSrc(media);

  return (
    <div>
      <Typography
        as="h1"
        weight="bold"
        beforeEnhancer={
          <Icon
            name="oa-specialoffers"
            size={24}
            $style={{ marginRight: theme.spacing(1) }}
          />
        }
        $style={{ marginBottom: '1rem' }}
      >
        News &amp; Special offers
      </Typography>
      <div
        className={css({
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: '4px',
          boxShadow: theme.lighting.shadow500,
          marginBottom: '1rem',
          width: '100%',
          '@media all and (min-width: 62em)': {
            flexDirection: 'row',
          },
        })}
      >
        {media && media.path && (
          <div
            className={css({
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: '100%',
              '@media all and (min-width: 62em)': {
                flexBasis: '300px',
                flexShrink: 0,
              },
              '@media all and (min-width: 80em)': {
                flexBasis: '400px',
              },
            })}
          >
            <Image
              src={contentStackImageSrc ?? `//assets.parkholidays.com/433x240/assets/${media.path}`}
              alt={title}
              aspectRatio="0.4"
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </div>
        )}
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            paddingTop: '1rem',
            paddingRight: '1rem',
            paddingBottom: '1rem',
            paddingLeft: '1rem',
            justifyContent: 'space-between',
          })}
        >
          <div>
            <Text weight="bolder">{title}</Text>
            <Text>{shortDescription}</Text>
          </div>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: theme.spacing(1),
            })}
          >
            <Anchor
              aria-label='view offer'
              href={`/special-offers${page.url}`}
              endIcon={
                <Icon
                  name="chevron-right"
                  size={16}
                  style={{ marginLeft: theme.spacing(1) }}
                />
              }
              style={{ textDecoration: 'none' }}
            >
              View Offer
            </Anchor>
          </div>
        </div>
      </div>
      <Anchor
        aria-label='view all new and special offers'
        href="/special-offers"
        endIcon={
          <Icon
            name="chevron-right"
            size={16}
            style={{ marginLeft: theme.spacing(1) }}
          />
        }
        $style={{ textDecoration: 'none' }}
      >
        <Typography as="strong" $style={{ display: 'inline ' }}>
          View All&nbsp;
        </Typography>
        <Typography as="strong" $style={{ display: 'inline ' }}>
          News &amp; Special offers
        </Typography>
      </Anchor>
    </div>
  )
}

SpecialOffer.propTypes = propTypes

export default SpecialOffer
