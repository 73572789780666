import { gql } from 'apollo-boost'

const GET_ALL_SPECIAL_OFFERS = gql`
  query($criteria: SpecialOfferCriteria, $filters: [Filter]) {
    allOwnershipSpecialOffers(criteria: $criteria, filters: $filters) {
      id
      title
      shortDescription
      page {
        url
      }
      order
      media {
        path
      }
    }
  }
`

export default GET_ALL_SPECIAL_OFFERS
