import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Image from '@parkholidays/legacy_components/dist/image/Image'
import Typography from '../../../components/ui/typography'
import Icon from '../../../components/ui/icon'
import Text from '@parkholidays/legacy_components/dist/text/Text'
import { useStyletron, ThemeContext } from '@parkholidays/legacy_components'

const propTypes = {
  title: PropTypes.string.isRequired,
  media: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  isCancelled: PropTypes.bool,
}

const Header = ({ title, media, isCancelled }) => {
  const [css] = useStyletron()
  const {palette, spacing} = React.useContext(ThemeContext)

  const styles = useMemo(() => {
    return {
      pageTitle: {
        margin: `${spacing(2)}px 0px`,
      },
      image: {
        width: '100%',
      },
      eventTitle: {
        backgroundColor: palette.ownership,
        color: palette.common.white,
        padding: `${spacing(2)}px`,
      },
    }
  }, [palette.common.white, palette.ownership, spacing])

  return (
    <React.Fragment>
      <div className={css(styles.pageTitle)}>
        <Typography as="h1" weight="bold">
          <Icon
            name="oa-calendar"
            size={24}
            $style={{ marginRight: '0.5rem' }}
          />
          Events
        </Typography>
      </div>

      <div
        className={css({
          position: 'relative',
          overflow: 'hidden',
          ...(isCancelled && { opacity: 0.7 }),
        })}
      >
        {isCancelled && (
          <div
            className={css({
              position: 'absolute',
              width: '200px',
              top: '15px',
              left: '-50px',
              textAlign: 'center',
              backgroundColor: palette.common.white,
              transform: 'rotate(-35deg)',
              WebkitTransform: 'rotate(-35deg)',
              letterSpacing: '1px',
              lineHeight: '40px',
              zIndex: 1,
            })}
          >
            <Text>Cancelled</Text>
          </div>
        )}

        {media && (
          <Image
            src={media.src}
            alt={media.alt}
            className={css(styles.image)}
            aspectRatio="0.4"
          />
        )}

        <div>
          <Typography
            as="h1"
            weight="bold"
            noMargins
            className={css(styles.eventTitle)}
          >
            {title}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  )
}

Header.propTypes = propTypes

export default Header
