import React, { useState } from 'react'
import { useStyletron } from 'styletron-react'

import OwnersLogo from '../../components/owners-logo'
import Anchor from '../../components/ui/anchor'
import Button from '../../components/ui/button'
import FormField from '../../components/ui/formfield'
import FormGroup from '../../components/ui/formgroup'
import Icon from '../../components/ui/icon'
import Notification from '../../components/ui/notification'
import Typography from '../../components/ui/typography'

import AuthContext from '../../context/auth'
import Theme from '../../context/theme'

import ContentRoot, { ContentContainer } from './auth.styled'
import styles from './styles'
import { CORPORATE, CORPORATE_DARK } from 'src/constants'

const ForgotPassword = () => {
  const { theme } = React.useContext(Theme)
  const auth = React.useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [notifications, setNotifications] = useState([])
  const [css] = useStyletron()
  const { ownersLogoStyles } = styles({ theme })

  const handleInputChange = e => {
    setEmail(e.target.value)
  }

  const onSubmit = async e => {
    e.preventDefault()
    const newNotifications = []
    const status = await auth.forgotPassword(email)
    if (!status.ok)
      newNotifications.push({ title: 'Error', messages: [status.message] })
    else
      newNotifications.push({
        context: 'success',
        pale: true,
        title: 'Success',
        messages: [
          'An email will be sent with a password reset link.',
          'Follow the link and fill out the form to update your password.',
        ],
      })

    setNotifications(newNotifications)
  }

  return (
    <ContentRoot>
      <ContentContainer $color={CORPORATE} $theme={theme}>
        <OwnersLogo width="200" className={css(ownersLogoStyles)} />
        <Typography as="h4">Forgot your password?</Typography>

        {notifications.map(notification => (
          <Notification
            {...notification}
            style={{ marginBottom: theme.spacing(2) }}
          />
        ))}

        <ul
          style={{
            marginTop: 0,
            // marginBottom: theme.spacing(2),
            textAlign: 'initial',
          }}
        >
          <li>Enter your email address below and click the link</li>
        </ul>

        <form onSubmit={onSubmit} style={{ textAlign: 'center' }}>
          <FormGroup>
            <FormField
              id="email"
              type="email"
              label="Email Address"
              labelProps={{ color: 'white' }}
              placeholder="i.e. Joebloggs@gmail.com"
              fullWidth
              onChange={handleInputChange}
              required
              value={email}
            />
          </FormGroup>

          <Button
            color={CORPORATE_DARK}
            fullWidth
            align="center"
            endIcon={<Icon name="chevron-right" />}
            style={{ marginBottom: theme.spacing(2) }}
          >
            Send Password Reset Link
          </Button>
        </form>
        <Anchor
          aria-label='login'
          href="/login"
          style={{ color: 'inherit', textDecoration: 'underline' }}
        >
          <Typography>Cancel</Typography>
        </Anchor>
      </ContentContainer>
    </ContentRoot>
  )
}

export default ForgotPassword
