import { useApolloClient } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'

import Anchor from '../../components/ui/anchor'
import Container from '../../components/ui/container'
import Typography from '../../components/ui/typography'
// import Card, { CardContent } from '../../components/ui/card'
import PrimaryButton from '@parkholidays/legacy_components/dist/button/PrimaryButton'
import Text from '@parkholidays/legacy_components/dist/text/Text'
import VisibleOn from '../../components/hoc/visible-on'
import OwnersLogo from '../../components/owners-logo'
import ParkSelectToolbar from '../../components/parkSelectToolbar'
import Divider from '../../components/ui/divider'
import Grid from '../../components/ui/grid'
import Icon from '../../components/ui/icon'

import {
  Events, ImportantMessage, ManagersMessage,
  SpecialOffer
} from './components'
import Notices from './notices'

import Loading from './../../components/ui/loading'
import GET_DASHBOARD_DATA from './graphql'

import ParkContext from '../../context/park'
import Theme from '../../context/theme'

import { MANAGERS_MESSAGE_TYPE_ID } from '../../config'
import { JSDateToGraphQL } from '../../helpers/formatDate'
import {OWNERS_LOGO_SMALL, myUrl} from 'src/constants'

const initialDashboardData = {
  allOwnershipSpecialOffers: [],
  allWebsiteAnnouncements: [],
  allParkNotices: [],
  allParkMessages: [],
  allEvents: [],
}

const Dashboard = () => {
  const { theme } = React.useContext(Theme)
  const { park } = React.useContext(ParkContext)
  const client = useApolloClient()

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const [loading, setLoading] = React.useState(true)
  const [dashboardData, setDashboardData] = React.useState(initialDashboardData)

  const handleResize = () => {
    const screenWidth = window.innerWidth
    setWindowWidth(screenWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    let mounted = true
    const today = new Date()
    const nextMonth = today.getMonth() < 11 ? today.getMonth() + 1 : 0
    const fullYear = today.getFullYear() + (today.getMonth() < 11 ? 0 : 1)
    const endDate = new Date(fullYear, nextMonth + 1, 0)

    if (park) {
      const getData = async () => {
        if (mounted) setLoading(true)
        try {
          const { data } = await client.query({
            query: GET_DASHBOARD_DATA,
            variables: {
              parkMessageFilters: [
                {
                  column: 'park_id',
                  operator: '=',
                  value: park.id,
                },
                {
                  column: 'park_message_type_id',
                  operator: '=',
                  value: MANAGERS_MESSAGE_TYPE_ID,
                },
                {
                  column: 'publish_at',
                  operator: '<=',
                  value: `${new Date()
                    .toISOString()
                    .substring(0, 10)} 00:00:00`,
                },
              ],
              specialOfferCriteria: {
                parkId: park.id,
              },
              specialOfferFilters: [
                {
                  column: 'publishAt',
                  operator: '<',
                  value: JSDateToGraphQL(new Date()),
                },
                {
                  column: 'expiresAt',
                  operator: '>=',
                  value: JSDateToGraphQL(new Date()),
                },
              ],
              eventCriteria: {
                startDate: new Date().toISOString().substr(0, 10),
                endDate: endDate.toISOString().substr(0, 10),
                parkId: park.id,
              },
              parkNoticeCriteria: {
                parkId: park.id,
              },
              parkNoticeFilters: [
                // {
                //   column: 'park_id',
                //   operator: '=',
                //   value: park.id,
                // },
                {
                  column: 'publishAt',
                  operator: '<=',
                  value: JSDateToGraphQL(new Date()),
                },
                {
                  column: 'expireAt',
                  operator: '>',
                  value: JSDateToGraphQL(new Date()),
                },
              ],
              websiteAnnouncementFilters: [
                {
                  column: 'park_id',
                  operator: '=',
                  value: park.id,
                },
                {
                  column: 'publish_at',
                  operator: '<=',
                  value: JSDateToGraphQL(new Date()),
                },
                {
                  column: 'expire_at',
                  operator: '>=',
                  value: JSDateToGraphQL(new Date()),
                },
              ],
              websiteAnnouncementCriteria: {
                tenantId: process.env.REACT_APP_OWNERS_AREA_TENANT_ID,
                sectorId: 3,
              },
              criteria: {
                parkId: park.id,
              },
            },
          })

          if (mounted) setDashboardData(data)
        } catch (err) {
          console.log(err)
        }

        if (mounted) setLoading(false)
      }

      getData()
    }

    return () => {
      mounted = false
    }
  }, [client, park])

  if (loading) {
    return <Loading />
  }

  const {
    allOwnershipSpecialOffers,
    allWebsiteAnnouncements,
    allParkNotices,
    allParkMessages,
    allEvents,
  } = dashboardData

  return (
    <>
      <ParkSelectToolbar />
      <Container
        $style={{
          '@media all and (min-width: 36em)': {
            paddingTop: '1rem',
          },
        }}
      >
        {allWebsiteAnnouncements && allWebsiteAnnouncements.length ? (
          <ImportantMessage message={allWebsiteAnnouncements[0]} />
        ) : null}

        <Grid style={{ marginTop: theme.spacing(2) }}>
          {allOwnershipSpecialOffers?.length ? (
            <Grid item xs={12} md={6} lg={8} style={{ display: 'block' }}>
              {allOwnershipSpecialOffers?.length ? (
                <>
                  <SpecialOffer
                    offer={
                      allOwnershipSpecialOffers.sort((a, b) =>
                        a.order < b.order ? 1 : a.order > b.order ? -1 : 0,
                      )[0]
                    }
                  />
                  <Divider />
                </>
              ) : null}

              {allParkMessages?.length ? (
                <>
                  <ManagersMessage
                    message={
                      allParkMessages.sort((a, b) =>
                        a.publishAt < b.publishAt
                          ? 1
                          : a.publishAt > b.publishAt
                          ? -1
                          : 0,
                      )[0]
                    }
                  />
                  <VisibleOn
                    component={<Divider />}
                    breakpoints={['xs', 'sm']}
                  />
                </>
              ) : null}
            </Grid>
          ) : null}

          {!allEvents?.length ? (
            <Grid item xs={12} md={6} lg={4} style={{ display: 'block' }}>
              <Events data={allEvents} />
            </Grid>
          ) : null}
        </Grid>

        {!!allParkNotices?.length ? (
          <>
            <Notices
              theme={theme}
              windowWidth={windowWidth}
              data={allParkNotices}
            />
            <Divider />
          </>
        ) : null}

        <Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              as="h1"
              weight="bold"
              beforeEnhancer={
                <Icon
                  name="oa-resources"
                  size={24}
                  style={{ marginRight: theme.spacing(1) }}
                />
              }
            >
              Information &amp; Resources
            </Typography>

            <Typography as="p">
              Download useful forms and documents including the latest lettings
              brochure and earning guides.
            </Typography>

            <Anchor
              aria-label='information and resources'
              href="/information-resources"
              endIcon={
                <Icon
                  name="chevron-right"
                  size={16}
                  style={{ marginLeft: theme.spacing(1) }}
                />
              }
              style={{ textDecoration: 'none' }}
            >
              <Typography as="strong" $style={{ display: 'inline' }}>
                View all&nbsp;
              </Typography>
              <Typography as="strong" $style={{ display: 'inline' }}>
                Information &amp; Resources
              </Typography>
            </Anchor>

            <VisibleOn component={<Divider />} breakpoints={['xs', 'sm']} />
          </Grid>

          {theme.isPl2k ? null : (
            <Grid item xs={12} md={6} lg={4}>
              <Typography
                as="h1"
                weight="bold"
                beforeEnhancer={
                  <Icon
                    name="oa-faqs"
                    size={24}
                    style={{ marginRight: theme.spacing(1) }}
                  />
                }
              >
                FAQs
              </Typography>

              <Typography as="p">
                In this section we try to answer some of the frequently asked
                questions about ownership.
              </Typography>

              <Anchor
                aria-label='frequently-asked-questions'
                href="/frequently-asked-questions"
                endIcon={
                  <Icon
                    name="chevron-right"
                    size={16}
                    style={{ marginLeft: theme.spacing(1) }}
                  />
                }
                style={{ textDecoration: 'none' }}
              >
                <Typography as="strong">View All&nbsp;</Typography>
                <Typography as="strong">FAQs</Typography>
              </Anchor>
            </Grid>
          )}

          <Grid item xs={12} lg={4}>
            <VisibleOn
              component={<Divider style={{ marginTop: 0 }} />}
              breakpoints={['xs', 'sm', 'md']}
            />
            <Typography
              as="h1"
              weight="bold"
              beforeEnhancer={
                <Icon
                  name="oa-myaccount"
                  size={24}
                  $style={{ marginRight: `${theme.spacing(1)}px` }}
                />
              }
            >
              MyAccount
            </Typography>

            <Typography as="p">
              Manage your account online; view transactions, make payments,
              submit maintenance requests and edit your profile.
            </Typography>

            {/* <Card color="corporate" $style={{ width: '100%' }}>
              <CardContent
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Anchor
                  native
                  href="https://my.parkholidays.com/"
                  target="_blank"
                  color="white"
                  style={{ textDecoration: 'none', textTransform: 'uppercase' }}
                >
                  MyAccount
                </Anchor>
                <OwnersLogo
                  style={{ width: 150, marginLeft: theme.spacing(1) }}
                />
              </CardContent>
            </Card> */}
            <PrimaryButton
              ariaLabel='Go to my account' 
              href={myUrl(theme.isPl2k)
              }
              openInNewWindow
              $style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text color="white">My Account</Text>
              {theme.isPl2k ?
                <img
                  src={OWNERS_LOGO_SMALL}
                  alt="Owners Lounge"
                  width="150px"
                />
              :
              <OwnersLogo
              style={{ width: 150, marginLeft: theme.spacing(1) }}
                />
              }
            </PrimaryButton>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Dashboard
