import React, { useContext } from 'react';
import Theme from '../../context/theme';
import Image from '../ui/image';
import {PH_LOGO, PL_LOGO, } from 'src/constants'

const PL_LOGO_ALT = "Park Leisure Owners Lounge Logo"
const PH_LOGO_ALT = "Park Holidays UK Owners Area Logo"

const OwnersLogo = ({...otherProps }) => {

  const { theme: {isPl2k} } = useContext(Theme);

  return (
    <Image
      {...otherProps}
      src={(isPl2k ? PL_LOGO : PH_LOGO)}
      alt={(isPl2k ? PL_LOGO_ALT : PH_LOGO_ALT)}
    />
  )
}

export default OwnersLogo
