import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from '@parkholidays/legacy_components'
import { format, parse } from 'date-fns'

import Image from '@parkholidays/legacy_components/dist/image/Image'
import Text from '@parkholidays/legacy_components/dist/text/Text'

import Anchor from '../../../components/ui/anchor'
import Icon from '../../../components/ui/icon'
import Typography from '../../../components/ui/typography'
import BBCode from '../../../components/bbcode'

import trimText from '../../../helpers/trimText'
import { applyColor } from 'src/constants'
import Theme from '../../../context/theme'
import { useContentStackImageSrc } from '../../../hooks/useContentStackImageSrc';

const propTypes = {
  message: PropTypes.shape({
    message: PropTypes.string.isRequired,
    publishAt: PropTypes.string.isRequired,
    park: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    contact: PropTypes.shape({
      firstname: PropTypes.string,
      surname: PropTypes.string,
      jobRole: PropTypes.shape({
        jobTitle: PropTypes.string,
      }),
      media: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
}

const ManagersMessage = ({
  message: { message, publishAt, park, contact },
}) => {
  const [css, theme] = useStyletron()
  const {theme:{isPl2k}} = useContext(Theme)

  const { name: parkName } = park
  const { firstname, surname, jobRole, media } = contact || {}
  const contentStackImageSrc = useContentStackImageSrc(media);

  return (
    <React.Fragment>
      <Typography
        as="h1"
        weight="bold"
        beforeEnhancer={
          <Icon
            name="oa-managersmessage"
            size={24}
            style={{ marginRight: theme.spacing(1) }}
          />
        }
      >
        Manager's Message
      </Typography>

      <div
        className={css({
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: '4px',
          boxShadow: theme.lighting.shadow500,
          marginBottom: '1rem',
          width: '100%',
          overflow: 'hidden',
        })}
      >
        <div
          style={{
            display: 'flex',
            flexdirection: 'row',
          }}
        >
          {media && media.path && (
            <Image
              src={contentStackImageSrc ?? `https://assets.parkholidays.com/100x100/assets/${media.path}`}
              alt={`${firstname} ${surname} Photo`}
              width="100px"
              height="100px"
              noLoading
            />
          )}
          <div
            className={css({
              width: '100%',
              paddingTop: '1rem',
              paddingRight: '1rem',
              paddingBottom: '1rem',
              paddingLeft: '1rem',
            })}
          >
            <Text $style={{ float: 'right', zIndex: '-1' }}>
              {format(
                parse(publishAt, 'yyyy-MM-dd HH:mm:ss', new Date()),
                'EEE do MMM',
              )}
            </Text>
            <Text weight="bolder">Greetings from {parkName}</Text>
            {jobRole && jobRole.jobTitle && (
              <Text color={applyColor(isPl2k)} $style={{ marginBottom: '0' }}>
                {jobRole.jobTitle}
              </Text>
            )}
            <Text>
              {firstname} {surname}
            </Text>
          </div>
        </div>
        <div
          className={css({
            paddingTop: '1rem',
            paddingRight: '1rem',
            paddingBottom: '1rem',
            paddingLeft: '1rem',
          })}
        >
          <BBCode>{trimText(message, 750)}</BBCode>
          <div style={{ textAlign: 'right' }}>
            <Anchor
              aria-label='read more'
              href="/managers-message"
              endIcon={<Icon name="chevron-right" />}
              style={{ textDecoration: 'none' }}
            >
              Read More
            </Anchor>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

ManagersMessage.propTypes = propTypes

export default ManagersMessage
