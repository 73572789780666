import { useApolloClient } from '@apollo/react-hooks'
import {
  addYears, format, getDate, getMonth, getYear, lastDayOfMonth, parse
} from 'date-fns'
import React from 'react'

import PaginationBar from '../../components/pagination/PaginationBar'
import ParkSelectToolbar from '../../components/parkSelectToolbar'
import Container from '../../components/ui/container'
import Icon from '../../components/ui/icon'
import Loading from '../../components/ui/loading'
import Typography from '../../components/ui/typography'
import EventsContent from './components/content/index'
import NextMonthPreview from './components/next-month-preview/index'

import ParkContext from '../../context/park'
import Theme from '../../context/theme'

import GET_ALL_EVENTS from './graphql'

const Events = () => {
  const client = useApolloClient()
  const { theme } = React.useContext(Theme)
  const { park } = React.useContext(ParkContext)

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const [state, setState] = React.useState({
    year: getYear(new Date()),
    month: getMonth(new Date()) + 1, // add 1 as it starts at 0
    events: [],
    nextMonthEvents: [],
    loading: false,
    error: '',
  })

  React.useEffect(() => {
    let mounted = true
    const getEvents = async () => {
      if (mounted)
        setState(prevState => ({
          ...prevState,
          loading: true,
        }))

      const { month, year } = state

      // We only want to show events that in now & in the future
      // so we need to check if we're on the current month & year,
      // then set the day to current day, otherwise set to '01'.
      let day = '01'
      if (month === getMonth(new Date()) + 1 && year === getYear(new Date())) {
        day = getDate(new Date())
      }

      const startDate = parse(`${day}-${month}-${year}`, 'd-M-yyyy', new Date())
      const endDate = lastDayOfMonth(
        parse(
          `01-${month + 1 > 12 ? 1 : month + 1}-${
            year + (month + 1 > 12 ? 1 : 0)
          }`,
          'd-M-yyyy',
          new Date(),
        ),
      )

      try {
        const { data } = await client.query({
          query: GET_ALL_EVENTS,
          variables: {
            criteria: {
              startDate: format(startDate, 'yyyy-MM-dd'),
              endDate: format(endDate, 'yyyy-MM-dd'),
              parkId: park.id,
            },
          },
        })

        if (mounted)
          setState(prevState => ({
            ...prevState,
            loading: false,
            events: data.allEvents.edges
              .filter(
                ({ node: event }) =>
                  event.startDate <=
                  format(lastDayOfMonth(startDate), 'yyyy-MM-dd'),
              )
              .sort((a, b) => a.node.startDate.localeCompare(b.node.startDate))
              .sort((a, b) => b.node.pinned - a.node.pinned),
            nextMonthEvents: data.allEvents.edges
              .filter(
                ({ node: event }) =>
                  event.startDate >
                  format(lastDayOfMonth(startDate), 'yyyy-MM-dd'),
              )
              .slice(0, 3)
              .sort((a, b) => a.node.startDate.localeCompare(b.node.startDate)),
          }))
      } catch (err) {
        if (mounted)
          setState(prevState => ({
            ...prevState,
            loading: false,
            error: err,
          }))
      }
    }

    if (mounted) getEvents()
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, park.id, state.month, state.year])

  // Pass increment as a positive or negative number, i.e.
  // 1 or -1, and can only change by a value of 1
  const onHandleChangeMonth = increment => {
    const { month, year } = state
    let newMonth
    let newYear

    if (month + increment < 1) {
      newMonth = 12
      newYear = year - 1
    } else {
      if (month + increment > 12) {
        newMonth = 1
        newYear = year + 1
      } else {
        newMonth = month + increment
        newYear = year
      }
    }

    setState(prevState => ({
      ...prevState,
      month: newMonth,
      year: newYear,
    }))
  }

  const { loading, error, events, nextMonthEvents, month, year } = state

  return (
    <React.Fragment>
      <ParkSelectToolbar />
      <Container style={{ marginTop: `${theme.spacing(4)}px` }} marginBottom>
        <Typography
          as="h1"
          weight="bold"
          beforeEnhancer={
            <Icon
              name="oa-calendar"
              size={24}
              style={{ marginRight: theme.spacing(1) }}
            />
          }
        >
          Upcoming Events
        </Typography>

        <PaginationBar
          pageLabel={`${monthNames[month - 1]} ${year}`}
          previousDisabled={
            !!(
              year === getYear(new Date()) && month - 1 <= getMonth(new Date())
            )
          }
          nextDisabled={
            !!(
              year > getYear(addYears(new Date(), 1)) &&
              month - 1 < getMonth(new Date())
            )
          }
          onHandlePreviousClick={() => onHandleChangeMonth(-1)}
          onHandleNextClick={() => onHandleChangeMonth(1)}
          style={{ marginBottom: '1rem' }}
        />

        {loading && <Loading />}

        {error && (
          <p>
            Whoops! Unfortunately we could not load the events. Please try again
            later.
          </p>
        )}

        {!loading && !error && (
          <React.Fragment>
            <EventsContent events={events} contextTheme={{isPl2k:theme.isPl2k, colors:theme.palette}} />

            {nextMonthEvents.length > 0 ?
              <NextMonthPreview
                events={nextMonthEvents}
                month={month}
                year={year}
                onHandleNextClick={() => onHandleChangeMonth(1)}
              />
            : null}
            {(events.length === 0 && nextMonthEvents.length === 0) ?
              <Typography as="h3">
                Unfortunately we don&apos;t have anything to show yet, but
                please check back soon.
              </Typography>
            : null}
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  )
}

export default Events
