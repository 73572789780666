import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  ariaHidden: PropTypes.bool,
  ariaLabel: PropTypes.string,
  role: PropTypes.string,
  style: PropTypes.string,
  viewBox: PropTypes.string,
  xmlns: PropTypes.string,
}

const defaultProps = {
  viewBox: '0 0 18 18',
  xmlns: 'http://www.w3.org/2000/svg',
  role: 'presentation',
}

const IconChevronNext = ({ viewBox, xmlns, role, ...props }) => (
  <svg viewBox={viewBox} xmlns={xmlns} role={role} {...props}>
    <path
      d="m4.29 1.71a1 1 0 1 1 1.42-1.41l8 8a1 1 0 0 1 0 1.41l-8 8a1 1 0 1 1 -1.42-1.41l7.29-7.29z"
      fillRule="evenodd"
    />
  </svg>
)

export default IconChevronNext
