import React from 'react';
import { Tag } from 'bbcode-to-react';
import Typography from '../../ui/typography';

class ParagraphTag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return <Typography as="p">{this.getComponents()}</Typography>;
  }
}

export default ParagraphTag;
