import React from 'react';
import PropTypes from 'prop-types';

import HrTitleRoot from './style';
import Theme from '../../../context/theme';

const HrTitle = ({ children, as, color, weight, ...otherProps }) => {
  const { theme } = React.useContext(Theme);
  return (
    <HrTitleRoot
      {...otherProps}
      $as={as}
      $color={color}
      $weight={weight}
      $theme={theme}
    >
      {children}
    </HrTitleRoot>
  );
};

HrTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  as: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.string,
};

HrTitle.defaultProps = {
  as: 'h1',
  color: 'inherit',
  weight: 'regular',
};

export default HrTitle;
