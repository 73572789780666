import { gql } from 'apollo-boost';

const SEND_COMPLAINT = gql`
    mutation CreateCustomerCareEmail($input: CreateCustomerCareEmailInput! ) {
    createCustomerCareEmail(input: $input) {
      success
    }
  }
`;

export default SEND_COMPLAINT;
