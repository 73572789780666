import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import AuthContext from '../../context/auth'
import Typography from '../../components/ui/typography'
import OwnersLogo from '../../components/owners-logo'

const Logout = () => {
  const auth = React.useContext(AuthContext)
  const [isLoggedOut, setIsLoggedOut] = useState(false)

  useEffect(() => {
    const signOut = async () => {
      const status = await auth.signOut()
      setIsLoggedOut(status.ok)
    }

    signOut()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <OwnersLogo width="200" style={{ marginBottom: 16 }} />
      <Typography as="h1">Logging out...</Typography>
      <Typography as="p">Please wait you are being logged out!</Typography>
      {isLoggedOut ? <Redirect to="/login" /> : null}
    </div>
  )
}

export default Logout
