import { useLazyQuery } from '@apollo/react-hooks'
import { LoadingContext } from '@parkholidays/legacy_components/dist/with-loading/LoadingContext'
import React, { useEffect } from 'react'

/** Components */
import Container from '../../components/ui/container'
import FormField from '../../components/ui/formfield'
import Icon from '../../components/ui/icon'
import Select from '../../components/ui/select'
import Typography from '../../components/ui/typography'
// import Button, { IconButton } from '../../components/ui/button'
import OwnershipButton from '@parkholidays/legacy_components/dist/button/OwnershipButton'
import ParkSelectToolbar from '../../components/parkSelectToolbar'
import Grid from '../../components/ui/grid'

import Navigation from './components/Navigation/Navigation'
import Resource from './components/Resource/Resource'

import ParkContext from '../../context/park'
import Theme from '../../context/theme'

import Loading from '../../components/ui/loading'
import GET_INFO_RES, { GET_ASSET_CATEGORIES } from './graphql'

import { encodeCursor } from '../../helpers'

const InformationResources = () => {
  const { park } = React.useContext(ParkContext)
  const { theme } = React.useContext(Theme)
  const [keyword, setKeyword] = React.useState('')
  const [category, setCategory] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(1)
  const pageSize = 8
  const initialFilters = {
    parkId: park.id,
    cursorPagination: {
      first: pageSize,
    },
    assetCriteria: {
      fuzzySearch: '',
    },
  }

  const [infoResourcesVariables, setInfoResourcesVariables] = React.useState(
    initialFilters,
  )

  const [getInfoResources, { loading, error, data }] = useLazyQuery(
    GET_INFO_RES,
  )

  const [
    getAssetCategories,
    { loading: catLoading, data: catData },
  ] = useLazyQuery(GET_ASSET_CATEGORIES)

  // const handleClearFilters = e => {
  //   e.preventDefault()
  //   setKeyword('')
  //   setCategory('')
  //   setInfoResourcesVariables({ ...initialFilters })
  // }

  useEffect(() => {
    getAssetCategories()
  }, [getAssetCategories])

  useEffect(() => {
    getInfoResources({ variables: infoResourcesVariables })
  }, [getInfoResources, infoResourcesVariables])

  const handlePageChange = page => {
    setCurrentPage(page)
    setInfoResourcesVariables(prevState => {
      return {
        ...prevState,
        cursorPagination: {
          first: pageSize,
          after: encodeCursor(page * pageSize - pageSize),
        },
      }
    })
  }

  const handleSearchClick = () => {
    setCurrentPage(1)
    setInfoResourcesVariables(prevState => {
      return {
        ...prevState,
        assetCriteria: {
          fuzzySearch: keyword,
          categoryId: category,
        },
        cursorPagination: {
          first: pageSize,
        },
      }
    })
  }

  const handleCategoryChange = value => {
    setCurrentPage(1)
    setInfoResourcesVariables(prevState => {
      return {
        ...prevState,
        assetCriteria: {
          categoryId: value,
        },
        cursorPagination: {
          first: pageSize,
        },
      }
    })
    setCategory(value)
  }

  if (catLoading || !catData) {
    return <Loading />
  }

  if (error) {
    return <p>Error</p>
  }

  const pageCount = data
    ? Math.ceil(data.allParks[0].ownersAreaResources.totalCount / pageSize)
    : 0
  const assets = data ? data.allParks[0].ownersAreaResources.edges : []

  return (
    <>
      <ParkSelectToolbar />
      <LoadingContext.Provider value={{ isLoading: loading }}>
        <Container $style={{ marginTop: `${theme.spacing(2)}px` }}>
          <Typography
            as="h1"
            weight="bold"
            beforeEnhancer={
              <Icon
                name="oa-resources"
                size={24}
                style={{ marginRight: theme.spacing(1) }}
              />
            }
          >
            Information &amp; Resources
          </Typography>

          <Select
            fullWidth
            select
            label="Select a category"
            placeholder="All Categories"
            value={category}
            onChange={e => handleCategoryChange(e.target.value)}
            options={[
              { label: 'All Categories', value: 0 },
              ...catData.allAssetCategories.map(category => ({
                label: category.name,
                value: category.id,
              })),
            ]}
            labelProps={{ srOnly: true }}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <FormField
              fullWidth
              placeholder="Search by keyword"
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
            />

            <OwnershipButton
              ariaLabel='search magnifier'
              onClick={() => handleSearchClick()}
              $style={{ marginLeft: '1rem' }}
            >
              <Icon name="magnifier" />
            </OwnershipButton>
          </div>

          {/* <div style={{ marginBottom: theme.spacing(2) }}>
            <Typography
              color="corporate"
              onClick={handleClearFilters}
              afterEnhancer={
                <Icon
                  name="cross"
                  style={{ marginRight: theme.spacing(1), cursor: 'pointer' }}
                />
              }
            >
              clear
            </Typography>
          </div> */}

          {/* <Button
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            first
          </Button>
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === pageCount}
          >
            Next
          </Button>
          <Button
            onClick={() => handlePageChange(pageCount)}
            disabled={currentPage === pageCount}
          >
            Last Page
          </Button> */}
        </Container>

        {loading && <Loading />}

        {!loading && (
          <Container $style={{ marginTop: `${theme.spacing(2)}px` }}>
            {data && data.allParks[0].ownersAreaResources.totalCount === 0 ? (
              <Typography as="h3">
                Unfortunately we don&apos;t have anything to show yet, but
                please check back soon.
              </Typography>
            ) : (
              <>
             {/* <Typography
                  as="p"
                  style={{ textAlign: 'center' }}
                >{`Showing page ${pageIndex} of ${pageCount} on ${data.park.ownersAreaResources.totalCount} matching files`}</Typography> */}
              <Grid alignItems="stretch">
              {assets.map(({ node: item }, i) => (
                    <Grid
                      key={`${item.id}_${i}`}
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      xl={3}
                    >
                      <Resource
                        asset={item.asset}
                        categoryName={item.category.name}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            </Container>
          )}
  
          {pageCount > 1 ? (
          <Navigation
            isPrevDisabled={currentPage === 1}
            onPrevClickHandler={() => handlePageChange(currentPage - 1)}
            isNextDisabled={currentPage >= pageCount}
            onNextClickHandler={() => handlePageChange(currentPage + 1)}
          />
        ) : null}
      </LoadingContext.Provider>
    </>
  )
}

export default InformationResources
