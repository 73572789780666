import {isParkLeisureContext} from "../../helpers/hostNameCheck";
import PropTypes from 'prop-types';
import React from 'react';

import actions from './actions';
/** THEME COLORS */
import accentColors from './colors/accent';
import brandColors from './colors/brand';
import commonColors from './colors/common';
import systemColors from './colors/system';
/** THEME METHODS */
import createBreakpoints from './functions/breakpoints';
import createMixins from './functions/mixins';
import createSpacing from './functions/spacing';
import styler from './functions/styler';
/** MISC */
import shadows from './shadows';
import shape from './shape';
import typography from './typography';

/** INITIAL STATE */
const initialState = {
  palette: {
    common: { ...commonColors },
    ...brandColors,
    ...accentColors,
    ...systemColors,
  },
  shape,
  shadows,
  typography,
  zIndex: {
    appbar: 1100,
    sideMenu: 1200,
    modal: 1300,
  },
  // GENERATED STYLES
  breakpoints: createBreakpoints(),
  mixins: createMixins(createBreakpoints(), createSpacing()),
  spacing: createSpacing(),
  styler,
  isPl2k: isParkLeisureContext()
};

/** CONTEXT */
const Theme = React.createContext(initialState);

/** REDUCER */
function reducer(state, action) {
  switch (action.type) {
    case actions.SET_THEME:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

/** PROVIDER */
export function ThemeProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <Theme.Provider value={{ theme: state, dispatchTheme: dispatch }}>
      {children}
    </Theme.Provider>
  );
}

/** Component Property Types */
// eslint-disable-next-line react/no-typos
ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Theme;
