import React from 'react'
import PropTypes from 'prop-types'
import IconChevronNext from './IconChevronNext'
import IconChevronPrev from './IconChevronPrev'

const DIRECTIONS = {
  left: 'left',
  right: 'right',
}

const propTypes = {
  direction: PropTypes.oneOf(Object.keys(DIRECTIONS)),
}

const defaultProps = {
  direction: DIRECTIONS.right,
}

const IconWithDirection = ({ direction, ...props }) => {
  return direction === DIRECTIONS.right ? (
    <IconChevronNext {...props} />
  ) : (
    <IconChevronPrev {...props} />
  )
}

export default IconWithDirection
