import React from 'react';
import { Tag } from 'bbcode-to-react';

class ULTag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return <ul>{this.getComponents()}</ul>;
  }
}

export default ULTag;
