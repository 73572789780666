import { styled } from 'styletron-react'

// Theme Styles
const themeStyles = ({ $color, $outlined, $theme }) => {
  if (!$color || ['inherit', 'initial'].includes($color)) return {}

  let color = $color === 'default' ? 'paper' : $color
  if ($outlined && $color === 'default') color = 'text'
  const styles = $theme.styler({ color, swap: $outlined, outlined: $outlined })

  return {
    ...styles,
    ...(!$outlined ? { border: 0 } : {}),
  }
}

const CardRoot = styled('div', ({ $theme, $rounded, ...otherProps }) => {
  const { shape, shadows } = $theme
  return {
    ...themeStyles({ ...otherProps, $theme }),
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
    paddingTop: 0,
    overflow: 'hidden',
    boxShadow: shadows[otherProps.$elevation],
    borderRadius: `${$rounded ? shape.medium : 0}px`,
  }
})

export const CardHeaderRoot = styled('div', ({ $theme, ...otherProps }) => {
  const { mixins, spacing } = $theme

  return {
    ...themeStyles({ ...otherProps, $theme }), // Colors
    ...mixins.gutters(),
    paddingTop: `${spacing(otherProps.$compact ? 0.5 : 1)}px`,
    paddingBottom: `${spacing(otherProps.$compact ? 0.5 : 1)}px`,
  }
})

export const CardContentRoot = styled('div', ({ $theme }) => ({
  ...$theme.mixins.gutters(),
  paddingTop: `${$theme.spacing(1)}px`,
  paddingBottom: `${$theme.spacing(1)}px`,
}))

export const CardActionsRoot = styled('div', ({ $theme }) => ({
  ...$theme.mixins.gutters(),
  paddingTop: `${$theme.spacing(1)}px`,
  paddingBottom: `${$theme.spacing(1)}px`,
}))

export const CardMediaRoot = styled('div', ({ $image }) => ({
  paddingTop: '56.25%',
  backgroundSize: 'cover',
  backgroundImage: `url(${$image})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '0px',
}))

export default CardRoot
