import React, { useEffect, useState } from 'react'

import Anchor from '../../components/ui/anchor'
import Button from '../../components/ui/button'
import CheckBox from '../../components/ui/checkbox'
import FormField, { PasswordField } from '../../components/ui/formfield'
import FormGroup from '../../components/ui/formgroup'
import FormLabel from '../../components/ui/formlabel'
import Notification from '../../components/ui/notification'
import Typography from '../../components/ui/typography'

import OwnersLogo from '../../components/owners-logo'

import AuthContext from '../../context/auth'
import Theme from '../../context/theme'
import ContentRoot, { ContentContainer } from './auth.styled'
import { CORPORATE, CORPORATE_DARK } from 'src/constants'
import { PL_PRIVACY_POLICY, PL_TNC} from '../../config'

const initialCredentialsState = {
  email: '',
  password: '',
  confirmPassword: '',
}

const Register = ({ match, location }) => {
  const auth = React.useContext(AuthContext)
  const { theme } = React.useContext(Theme)
  const [notification, setNotification] = useState(null)
  const [credentials, setCredentials] = useState(initialCredentialsState)

  const handleInputChange = e => {
    setCredentials({ ...credentials, [e.target.id]: e.target.value })
  }

  const isFormValid = () => {
    const isValid = credentials.password === credentials.confirmPassword
    if (!isValid)
      setNotification({
        title: 'Error',
        target: 'password',
        messages: ['Passwords do not match'],
        context: 'error',
        pale: true,
        // isValidation: true,
      })

    return isValid
  }

  const onSubmit = async e => {
    e.preventDefault()
    if (!isFormValid()) return false

    const status = await auth.signUp(credentials.email.toLowerCase(), credentials.password)
    const msg = status.ok
      ? {
          title: 'Success',
          messages: [
            `A verification email has been sent to ${status.data.user.username}`,
          ],
          color: 'green',
        }
      : { color: 'error', title: 'Error', messages: [status.message] }

    setNotification(msg)
    if (status.ok) setCredentials(initialCredentialsState)
  }

  useEffect(() => {
    console.log('useEffect')
    const query = new URLSearchParams(location.search)
    console.log('query', JSON.stringify(query))
    const email = query.get('email')
    console.log('email', JSON.stringify(email))
    if (email) setCredentials({ ...credentials, email, emailDisabled: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContentRoot>
      <ContentContainer $theme={theme} $color={CORPORATE}>
        <div>
          <OwnersLogo width="200" style={{ marginBottom: theme.spacing(2) }} />
          <Typography as="h4">Create an Account</Typography>
          {!credentials.emailDisabled &&
            <Typography as="p">Please sign up using the email address held on your {theme.isPl2k ? "Park Leisure" : "Park Holidays UK"} account</Typography>
          }
          <form onSubmit={onSubmit} autocomplete="off">
            {notification && !notification.isValidation ? (
              <Notification
                {...notification}
                style={{ marginBottom: theme.spacing(1) }}
              />
            ) : null}

            <FormGroup>
              <FormField
                id="email"
                type="email"
                label="Email Address"
                labelProps={{ color: 'white' }}
                fullWidth
                onChange={
                  credentials.emailDisabled ? () => {} : handleInputChange
                }
                required
                readOnly={credentials.emailDisabled}
                value={credentials.email}
              />
            </FormGroup>

            <FormGroup>
              <PasswordField
                autoComplete="off"
                id="password"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$"
                label="Password"
                labelProps={{ color: 'white' }}
                // helperText={
                //   notification && notification.target === 'password'
                //     ? notification.messages[0]
                //     : null
                // }
                hasError={notification && notification.target === 'password'}
                fullWidth
                required
                value={credentials.password}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <PasswordField
                autoComplete="off"
                id="confirmPassword"
                type="password"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$"
                label="Confirm Password"
                labelProps={{ color: 'white' }}
                // helperText={
                //   notification && notification.target === 'password'
                //     ? notification.messages[0]
                //     : null
                // }
                hasError={notification && notification.target === 'password'}
                fullWidth
                required
                value={credentials.confirmPassword}
                onChange={handleInputChange}
              />
            </FormGroup>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography weight="bold">Passwords must:</Typography>
              <ul style={{ textAlign: 'initial' }}>
                <li>
                  <Typography>Be between 8 and 30 characters</Typography>
                </li>
                <li>
                  <Typography>Contain at least one digit</Typography>
                </li>
                <li>
                  <Typography>
                    Contain at least one uppercase character
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Contain at least one lowercase character
                  </Typography>
                </li>
              </ul>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <FormLabel
                control={<CheckBox required />}
                style={{ marginBottom: theme.spacing(1) }}
              >
                <Typography color="white">
                  {'I have read and agree to your '}
                  <Anchor aria-label='Privacy Policy' href={theme.isPl2k ? PL_PRIVACY_POLICY : "/privacy-policy"} color="white">
                    Privacy Policy
                  </Anchor>
                  {' and '}
                  <Anchor aria-label='Terms and Conditions' href={theme.isPl2k ? PL_TNC : "/terms-conditions"} color="white">
                    Terms and Conditions
                  </Anchor>
                </Typography>
              </FormLabel>

              <Button
                color={CORPORATE_DARK}
                style={{
                  width: '90%',
                  maxWidth: 300,
                  marginTop: theme.spacing(2),
                }}
              >
                CREATE ACCOUNT
              </Button>
              <br />

              <Anchor aria-label='login' href="/login" color="white">
                Already have an account?
              </Anchor>
            </div>
          </form>
        </div>
      </ContentContainer>
    </ContentRoot>
  )
}

export default Register
