export default {
  white: '#ffffff',
  black: '#000000',
  // GRAY
  gray: '#585858',
  gray50: '#EFEFEF', // PALE
  gray100: '#E0E0E0',
  gray200: '#C2C2C2',
  gray300: '#767676',
  gray400: '#585858', // REGULAR
  gray500: '#494949',
  gray600: '#414141',
  gray700: '#303030',
  gray800: '#202020',
  gray900: '#101010',
  // RED - ERROR
  red: '#CC0000',
  red50: '#F5D0D0', // PALE
  red100: '#E78B8B',
  red200: '#DE5C5C',
  red300: '#D52E2E',
  red400: '#CC0000', // REGULAR
  red500: '#A70000',
  red600: '#820000',
  red700: '#700000',
  red800: '#4B0000',
  red900: '#260000',
  // BLUE - CORPORATE
  blue: '#0c5595',
  blue50: '#D2E0EB', // PALE
  blue100: '#A6C1D8',
  blue200: '#6492BB',
  blue300: '#3873A8',
  blue400: '#0c5595', // REGULAR
  blue500: '#0A467A',
  blue600: '#08375F',
  blue700: '#062744',
  blue800: '#051F37',
  blue900: '#03101C',
  // CYAN - HOLIDAYS
  cyan: '#2e9cc0',
  cyan50: '#D9EDF3', // PALE
  cyan100: '#B3DBE8',
  cyan200: '#54AECB',
  cyan300: '#54AECB',
  cyan400: '#2e9cc0', // REGULAR
  cyan500: '#26809E',
  cyan600: '#1E647B',
  cyan700: '#1A5669',
  cyan800: '#113946',
  cyan900: '#091D23',
  // GREEN - TOURING
  green: '#87B026',
  green50: '#E9F0D7', // PALE
  green100: '#D3E2B0',
  green200: '#B2CC74',
  green300: '#9CBE4D',
  green400: '#87B026', // REGULAR
  green500: '#6F9120',
  green600: '#567119',
  green700: '#4A6015',
  green800: '#32400E',
  green900: '#192007',
  // PURPLE - OWNERS
  purple: '#962067',
  purple50: '#EBD6E3', // PALE
  purple100: '#D8ADC7',
  purple200: '#BC719E',
  purple300: '#A94882',
  purple400: '#962067', // REGULAR
  purple500: '#7B1B55',
  purple600: '#601542',
  purple700: '#521239',
  purple800: '#370C26',
  purple900: '#1C0613',
  // MAGENTA - ROSE
  magenta: '#f9068f',
  magenta50: '#FDD1EA', // PALE
  magenta100: '#FC8DCC',
  magenta200: '#FB60B7',
  magenta300: '#FA33A3',
  magenta400: '#f9068f', // REGULAR
  magenta500: '#CC0576',
  magenta600: '#9F045C',
  magenta700: '#88044E',
  magenta800: '#5B0334',
  magenta900: '#2E021A',
  // ORANGE - SUN
  orange: '#F99806',
  orange50: '#FDECD1', // PALE
  orange100: '#FCD08D',
  orange200: '#FBBD60',
  orange300: '#FAAA33',
  orange400: '#F99806', // REGULAR
  orange500: '#CC7D05',
  orange600: '#9F6104',
  orange700: '#885304',
  orange800: '#5B3803',
  orange900: '#2E1C02',
  // BROWN - SALE
  brown: '#AB2B24',
  brown50: '#EFD8D7', // PALE
  brown100: '#D89E9B',
  brown200: '#C97873',
  brown300: '#BA514B',
  brown400: '#AB2B24', // REGULAR
  brown500: '#8C241E',
  brown600: '#6D1C17',
  brown700: '#5E1814',
  brown800: '#3F100E',
  brown900: '#200807',
  // Pl2k
  gold100: '#b6a976',
  blue350: '#26b1bd',
  blue360: '#26b1bdcc',
  gray25: '#e5e0d4',
  blue950: '#1c4268',
  blue1000: '#06181e',
};
