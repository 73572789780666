import React, { useContext, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import OwnersLogo from '../../components/owners-logo'
import PasswordResetForm from '../../components/password-reset-form'
import Notification from '../../components/ui/notification'
import AuthContext from '../../context/auth'
import Theme from '../../context/theme'

import ContentRoot, { ContentContainer } from './auth.styled'
import { CORPORATE } from 'src/constants'

const PasswordReset = () => {
  const { theme } = React.useContext(Theme)
  const auth = useContext(AuthContext)
  const { username, code } = useParams()

  const [completed, setCompleted] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [notification, setNotification] = useState(null)

  /** METHODS */
  const handleResetPassword = async (password) => {
    const status = await auth.resetPassword(
      username,
      code,
      password,
    )

    if (!status.ok)
      setNotification({
        context: 'error',
        title: 'Error',
        messages: [status.message]
      })
    else setSuccessful(true);

    setCompleted(true)
  }

 const handleInvalidForm = () => setNotification({
    context: 'error',
    title: 'Error',
    messages: ['Your new password and confirmation do not match'],
  })

  if (completed  && successful) return <Redirect to="/" />

  return (
    <ContentRoot>
      <ContentContainer $color={CORPORATE} $theme={theme}>
        <OwnersLogo width="200" style={{ marginBottom: theme.spacing(2) }} />
        <PasswordResetForm
          isCompleted={completed}
          notificaiton={notification ? <Notification {...notification} /> : null}
          onSubmit={handleResetPassword}
          onInvalid={handleInvalidForm}
        />
      </ContentContainer>
    </ContentRoot>
  )
}

export default PasswordReset
