import { styled } from 'styletron-react'
const ContentRoot = styled('main', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
})
export const ContentContainer = styled('div', ({ $color, $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  // maxWidth: '320px',
  textAlign: 'center',
  ...($color
    ? $theme.styler({ color: $color, swap: false, outlined: false })
    : {}),
  paddingTop: `${$theme.spacing(4)}px`,
  paddingBottom: `${$theme.spacing(4)}px`,
  ...$theme.mixins.gutters(),
}))
export default ContentRoot
