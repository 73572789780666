import { styled } from '@parkholidays/legacy_components';
import OwnershipButton from '@parkholidays/legacy_components/dist/button/OwnershipButton';
import PropTypes from 'prop-types';
import React from 'react';
import Theme from '../../../../context/theme';

const propTypes = {
  path: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

const ResourceFooter = ({ size, path, className }) => {
  const {
    theme: { isPl2k },
  } = React.useContext(Theme)

  let downloadUrl = isPl2k
    ? '//assets.parkleisure.co.uk/owners-area-resources'
    : '//assets.parkholidays.com/owners-area-resources'

  return (
    <footer className={className}>
      {/* <Text>{(size / 1024).toFixed(1)}MB</Text> */}
      <OwnershipButton ariaLabel='download the resource' href={`${downloadUrl}/${path}`} openInNewWindow={true} >
        Download
      </OwnershipButton>
      </footer>)
};

ResourceFooter.propTypes = propTypes;

export default styled(ResourceFooter, {
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  paddingTop: '1rem',
  paddingRight: '1rem',
  paddingBottom: '1rem',
  paddingLeft: '1rem',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
});
