import React from 'react';
import { Tag } from 'bbcode-to-react';

class LITag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return <li>{this.getComponents()}</li>;
  }
}

export default LITag;
