import {isParkLeisureContext} from "./helpers/hostNameCheck";
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import AuthContext from './context/auth';
import ParkContext from './context/park';
import Loading from './components/ui/loading';
import Layout from './layout';
import routes from './routes';
import PageNotFound, { NoPark, MigrationNotice, OwnershipChange } from './views';
const AuthenticatedRoute = ({
  component: Component,
  auth,
  routeProps,
  ...rest
}) => {
  const location = useLocation()
  const goToProfile =
    auth.user && auth.user.sub.contactNo === null && location.pathname !== '/profile'
  let redirectPath = goToProfile ? '/profile' : '/login'
  if (auth.forcePasswordChange) redirectPath = '/login'
  const redirect = auth.isGuest || auth.forcePasswordChange || goToProfile ? true : false
  console.log({redirect})
  return (
    <Route
      {...rest}
      render={props =>
        redirect ? (
          <Redirect
            to={{ pathname: redirectPath, state: { from: location } }}
          />
        ) : (
          <Component {...props} {...routeProps} />
        )
      }
    />
  )
}

const App = () => {
  const auth = useContext(AuthContext)
  const { park, isLoading } = useContext(ParkContext)
  let newRoutes = [...routes]
  const location = useLocation()

  if (!isLoading && !auth.forcePasswordChange) {
    if (!park && !auth.isGuest) {
      newRoutes = newRoutes
        .filter(route => route.isPublic)
        .concat([{ path: '/', component: NoPark, hideLayout: true }])
    }

    const parkLeisureContext = isParkLeisureContext()

    // Migration notice for Amble Links owners (only on Park Leisure version of website)
    if (park && park.code === 'AL' && parkLeisureContext) {
      if (auth && auth.user.email === 'bydowyke@ema-sofia.eu') {
        console.log('Logging details');
        console.log(auth.user.email);
        console.log('-----Redirect to migration notice-----');
        newRoutes = newRoutes
        .filter(route => route.isPublic)
        .concat([{ path: '/', component: MigrationNotice, hideLayout: true }])
      }
    }
     // Ownership change notice for Littondale Holiday Park owners
    if (park && park.code === 'LD') {
      newRoutes = newRoutes
      .filter(route => route.isPublic)
      .concat([{ path: '/', component: OwnershipChange, hideLayout: true }])
    }
  }

  useEffect(() => {
    auth.verifySession();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search
    });
    if (park) {
      window.gtag("event", "park_code", {
        park_code: park.code
      });
    }
    // Combined tag
    if (park) {
      window.gtag("event", "page_park", {
        page_path: location.pathname + location.search,
        park_code: park.code
      });
    }
  }, [location, park]);

  if (isLoading && !auth.forcePasswordChange) return <Loading />
  return (
    <Layout routes={newRoutes} isLoggedIn={!auth.isGuest}>
      <Switch>
        {newRoutes.map(route => {
          const Component = route.component
          return route.isPublic ? (
            <Route
              exact={route.exact}
              path={route.path}
              key={route.path}
              render={props => (
                <Component {...props} {...(route.props || {})} />
              )}
            />
          ) : (
            <AuthenticatedRoute
              exact
              path={route.path}
              key={route.path}
              component={route.component}
              auth={auth}
              routeProps={route.props}
            />
          )
        })}

        <Route component={PageNotFound} />
      </Switch>
    </Layout>
  )
}

AuthenticatedRoute.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  routeProps: PropTypes.object,
}

AuthenticatedRoute.defaultProps = { user: null, routeProps: {} }

export default App
