import { styled } from 'styletron-react';

export const ParkWrapper = styled('div', ({ $theme }) => ({
  boxSizing: 'border-box',
  height: '38px',
  lineHeight: '38px',
  backgroundColor: $theme.palette.corporate,
  color: $theme.palette.common.white,
  cursor: 'pointer',
}));

export const ParkDropdown = styled('div', {
  boxSizing: 'border-box',
  position: 'relative',
  lineHeight: '1',
});

export const ParkDropdownContent = styled('div', ({ $open, $theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  display: $open ? 'block' : 'none',
  position: 'absolute',
  backgroundColor: $theme.palette.common.white,
  color: $theme.palette.common.black,
  zIndex: '1',
  boxShadow: '2px 2px 5px 0px #b7b7b7',
}));
