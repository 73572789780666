import { gql } from 'apollo-boost';

const GET_PAGE = gql`
  query($filters: [Filter]) {
    allPages(filters: $filters) {
      id
      data
      name
      pageTemplate {
        name
        path
      }
    }
  }
`;

export default GET_PAGE;
