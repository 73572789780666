import React from 'react'
import PropTypes from 'prop-types'
import parser from 'bbcode-to-react'

import { BBCodeContainer } from './style'
import { LITag, ParagraphTag, OLTag, ULTag } from './tags'

const bbcodeProptypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}

// Custom tag registration....
parser.registerTag('li', LITag)
parser.registerTag('ol', OLTag)
parser.registerTag('p', ParagraphTag) // replace exists tag
parser.registerTag('ul', ULTag)

const BBCode = ({ children, ...otherProps }) => {
  return <BBCodeContainer {...otherProps}>{parser.toReact(children)}</BBCodeContainer>
}

BBCode.propTypes = bbcodeProptypes

export default BBCode
