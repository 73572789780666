import React from 'react'

import Anchor from '../../../components/ui/anchor'
import EventCard from '../../events/components/EventCard/EventCard'
import Icon from '../../../components/ui/icon'
import Typography from '../../../components/ui/typography'
// import Grid from '../../../components/ui/grid'

import Theme from '../../../context/theme'

const Events = ({ data }) => {
  const { theme } = React.useContext(Theme)

  const events =
    data && data.edges && data.edges.length
      ? data.edges
          // sort by start date
          .sort((a, b) => a.node.startDate.localeCompare(b.node.startDate))
          // sort by 'pinned' events, to hoist to the top
          .sort((a, b) => b.node.pinned - a.node.pinned)
          // take first two
          .slice(0, 2)
      : []

  return (
    <>
      <Typography
        as="h1"
        weight="bold"
        beforeEnhancer={
          <Icon
            name="oa-calendar"
            size={24}
            style={{ marginRight: theme.spacing(1) }}
          />
        }
      >
        Upcoming Events
      </Typography>

      {events.length ? (
        events.map(
          ({
            node: {
              id,
              pinned,
              type,
              cancelled,
              title,
              startDate,
              endDate,
              slug,
              category,
              media,
            },
          }) => (
            <EventCard
              key={id}
              isOwnersExclusive={type && type.name === 'Owners Only'}
              isPinned={pinned}
              isCancelled={cancelled}
              title={title}
              startDate={startDate}
              endDate={endDate}
              slug={slug}
              type={type.name}
              category={category.name}
              defaultCategoryMedia={category.media}
              media={media}
              contextTheme={{isPl2k: theme.isPl2k, colors: theme.palette}}
            />
          ),
        )
      ) : (
        <div>There are currently no upcoming owner events at your park</div>
      )}

      <Anchor
        aria-label='view all events'
        href="/events"
        style={{ textDecoration: 'none', color: theme.palette.corporate }}
        endIcon={
          <Icon
            name="chevron-right"
            size={16}
            style={{ marginLeft: theme.spacing(1) }}
          />
        }
      >
        <Typography as="strong">View All&nbsp;</Typography>
        <Typography as="strong">Events</Typography>
      </Anchor>
    </>
  )
}

export default Events
