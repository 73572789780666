export default [
  {
    label: 'Home',
    link: '/',
    external: false,
  },
  {
    label: 'News & Special offers',
    link: '/special-offers',
    external: false,
  },
  {
    label: 'Managers Message',
    link: '/managers-message',
    external: false,
  },
  {
    label: 'Events',
    link: '/events',
    external: false,
  },
  {
    label: 'Information & Resources',
    link: '/information-resources',
    external: false,
  },
  {
    label: 'FAQs',
    link: '/frequently-asked-questions',
    external: false,
  },
  {
    label: 'Customer Care',
    link: '/customer-care',
    external: false,
  },
  {
    label: 'Contact Us',
    link: '/contact-us',
    external: false,
  }
]
