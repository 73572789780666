import React from 'react'
import { useApolloClient } from '@apollo/react-hooks'

import Container from '../../components/ui/container'
import Icon from '../../components/ui/icon'
import ParkSelectToolbar from '../../components/parkSelectToolbar'
import Typography from '../../components/ui/typography'

// import Cards from '../../components/contact-us/cards'
import Contact from './components/Contact'
import Theme from '../../context/theme'
import ParkContext from '../../context/park'

import GET_ALL_CONTACTS from './graphql'
import Loading from './../../components/ui/loading'

const ContactUs = () => {
  const { park } = React.useContext(ParkContext)
  const { theme } = React.useContext(Theme)

  const client = useApolloClient()

  const [state, setState] = React.useState({
    loading: false,
    contacts: [],
  })

  React.useEffect(() => {
    let mounted = true

    const getContacts = async () => {
      setState(prevState => ({ ...prevState, loading: true }))
      try {
        const { data } = await client.query({
          query: GET_ALL_CONTACTS,
          variables: {
            criteria: {
              parkId: park.id,
            },
          },
        })

        if (mounted)
          setState(prevState => ({
            loading: false,
            contacts: data.allParkContacts,
          }))
      } catch (err) {
        console.log(err)
      }
    }

    getContacts()
  }, [client, park.id])

  const { loading, contacts } = state

  return (
    <>
      <ParkSelectToolbar />
      <Container $style={{ marginTop: `${theme.spacing(2)}px` }}>
        <Typography
          as="h1"
          weight="bold"
          beforeEnhancer={
            <div
              style={{
                backgroundColor: '#4F4F4F',
                height: 25,
                width: 25,
                display: 'inline-block',
                borderRadius: '50%',
                textAlign: 'center',
                marginRight: theme.spacing(1),
              }}
            >
              <Icon name="oa-contactus" color="white" />
            </div>
          }
        >
          Contact Us
        </Typography>

        <Typography as="p" style={{ marginBottom: 0 }}>
          Do you have a question or query?
        </Typography>
        <Typography as="p">
          Contact a member of your park team and they will be in touch to assist
          you.
        </Typography>
      </Container>

      <Container>
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {contacts.map(
              ({
                id,
                firstname,
                surname,
                phoneNo,
                mobilePhoneNo,
                email,
                jobRole,
                media,
              }) => (
                <Contact
                  key={id}
                  firstname={firstname}
                  surname={surname}
                  phoneNo={phoneNo}
                  mobilePhoneNo={mobilePhoneNo}
                  email={email}
                  jobRole={jobRole}
                  media={media}
                  isPl2k={theme.isPl2k}
                />
              ),
            )}
          </React.Fragment>
        )}
      </Container>
    </>
  )
}

export default ContactUs
