import React from 'react'
import PropTypes from 'prop-types'
import { styled, useStyletron } from '@parkholidays/legacy_components'
import Icon from '../../../components/ui/icon'

import Image from '@parkholidays/legacy_components/dist/image/Image'
import Text from '@parkholidays/legacy_components/dist/text/Text'
import { applyColor, CORPORATE, PL2KBLUE } from 'src/constants'
import { useContentStackImageSrc } from '../../../hooks/useContentStackImageSrc';

const propTypes = {
  firstname: PropTypes.string,
  surname: PropTypes.string,
  phoneNo: PropTypes.string,
  mobilePhoneNo: PropTypes.string,
  email: PropTypes.string,
  jobRole: PropTypes.shape({
    jobTitle: PropTypes.string,
  }),
  media: PropTypes.shape({
    path: PropTypes.string,
    alternateText: PropTypes.string,
  }),
  className: PropTypes.string,
}

const styles = {
  profilePicture: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 'auto',
    paddingRight: '1rem',
  },
  details: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
  },
}

const Contact = ({
  firstname,
  surname,
  phoneNo,
  mobilePhoneNo,
  email,
  jobRole,
  media,
  className,
  isPl2k,
}) => {
  const [css] = useStyletron()
  const fullName = [firstname, surname].join(' ')
  const jobTitle = jobRole && jobRole.jobTitle && jobRole.jobTitle
  // The Pl2KBlue needs to be used for both corporate color and ownership color here 
  const ownershipColor = applyColor(isPl2k)
  const corporateColor = !isPl2k ? CORPORATE : PL2KBLUE
  const contentStackImageSrc = useContentStackImageSrc(media);
  return (
    <article className={className}>
      {media && media.path && (
        <div className={css(styles.profilePicture)}>
          <Image
            src={contentStackImageSrc ?? `https://assets.parkholidays.com/100x100/assets/${media.path}`}
            alt={`${fullName} - ${jobRole}`}
            noLoading
          />
        </div>
      )}
      <div className={css(styles.details)}>
        {jobTitle && (
          <Text color={ownershipColor} $style={{ marginBottom: '0.5rem' }}>
            {jobTitle}
          </Text>
        )}
        {fullName.length > 3 && <Text weight="bolder">{fullName}</Text>}
        {phoneNo && (
          <Text color={ownershipColor}>
          Tel:{' '}
          <Text weight="bolder" color={ownershipColor} inline>
              <a
                href={`tel:${phoneNo}`}
                style={{
                  font: 'inherit',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                {phoneNo}
              </a>
            </Text>
          </Text>
        )}
        {mobilePhoneNo && (
          <Text color={ownershipColor}>
            Mob:{' '}
            <Text weight="bolder" color={ownershipColor} inline>
              <a
                href={`tel:${mobilePhoneNo}`}
                style={{
                  font: 'inherit',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                {mobilePhoneNo}
              </a>
            </Text>
          </Text>
        )}
        {email && (
          <Text color={corporateColor}>
            <Icon name="envelope-o" size={16} />{' '}
            <Text weight="bolder" color={corporateColor} inline>
              <a
                href={`mailto:${email}`}
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                Email
              </a>
            </Text>
          </Text>
        )}
      </div>
    </article>
  )
}

Contact.propTypes = propTypes

export default styled(Contact, ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'nowrap',
  boxShadow: $theme.lighting.shadow500,
  backgroundColor: $theme.palette.common.white,
  marginBottom: '1rem',
  paddingTop: '1rem',
  paddingRight: '1rem',
  paddingBottom: '1rem',
  paddingLeft: '1rem',
}))
