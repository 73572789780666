/* eslint-disable */
import React, { useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import Container from '../../components/ui/container'
import Typography from '../../components/ui/typography'
import Select from '../../components/ui/select'
import Icon from '../../components/ui/icon'
import Button, { IconButton } from '../../components/ui/button'
import FormField from '../../components/ui/formfield'
import Grid from '../../components/ui/grid'
import VisibleOn from '../../components/hoc/visible-on'
import Theme from '../../context/theme'
import FaqContactUs from './components/faq-contactUs'
import BBCode from '../../components/bbcode'
import Loading from '../../components/ui/loading'

import GET_FAQS from './graphql'
import { FAQ, FAQHeader, FAQContent } from './components'

const Faqs = () => {
  const { theme } = React.useContext(Theme)
  const client = useApolloClient()

  const [keyword, setKeyword] = useState('')
  const [category, setCategory] = useState('')
  const [loading, setLoading] = React.useState(true)
  const [catData, setCatData] = useState([])
  const [faqs, setFaqs] = React.useState([])

  // Clear data in state
  const handleClearFilters = e => {
    e.preventDefault()

    setKeyword('')
    setCategory('')
  }

  let allowedCategories = new Set()
  let categories = []
  let selectedCategory = null

  React.useEffect(() => {
    let cancelled = false
    const getFaqs = async () => {
      setLoading(true)
      try {
        const { data } = await client.query({
          query: GET_FAQS,
          variables: {
            criteria: {
              tenantId: theme.isPl2k ? process.env.REACT_APP_OWNERS_LOUNGE_TENANT_ID : process.env.REACT_APP_OWNERS_AREA_TENANT_ID,
              sectorId: 3,
            },
          },
        })

        if (!cancelled) {
          setCatData(data.allFaqCategories)
          setFaqs(data.allFaqs)
        }
      } catch (err) {
        console.log(err)
      }

      if (!cancelled) setLoading(false)
    }

    getFaqs()
    return () => {
      cancelled = true
    }
  }, [client])

  if (catData) {
    allowedCategories = [
      ...new Set(faqs.filter(faq => faq.category).map(faq => faq.category.id)),
    ]
    categories = catData.filter(cat => allowedCategories.includes(cat.id))
    selectedCategory = categories.find(cat => cat.id === category) || null
  }

  const filteredFaqs = faqs
    // filter based on category, if exists...
    .filter(({ category: c }) => !category || category === (c || {}).id)
    // filter based on keyword, if exists...
    .filter(
      ({ question, answer }) =>
        !keyword ||
        question.toLowerCase().includes(keyword.toLowerCase()) ||
        answer.toLowerCase().includes(keyword.toLowerCase()),
    )

  return (
    <Container $style={{ marginTop: `${theme.spacing(2)}px` }}>
      <Grid>
        <Grid item xs={12} md={6} lg={4} style={{ display: 'block' }}>
          <Typography
            as="h1"
            weight="bold"
            beforeEnhancer={
              <Icon
                name="oa-faqs"
                size={24}
                style={{ marginRight: theme.spacing(1) }}
              />
            }
          >
            Frequently Asked Questions
          </Typography>

          <div style={{ width: '100%', marginBottom: theme.spacing(1) }}>
            <Select
              fullWidth
              label="Select category"
              placeholder="Select category"
              onChange={e => setCategory(e.target.value)}
              value={category}
              options={categories.map(category => ({
                label: category.title,
                value: category.id,
              }))}
              labelProps={{ srOnly: true }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FormField
              fullWidth
              placeholder="Search by keyword"
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
              $style={{
                marginBottom: `${theme.spacing(2)}px`,
                marginRight: `${theme.spacing(1)}px`,
              }}
            />
            <IconButton color="ownership">
              <Icon name="magnifier" />
            </IconButton>
          </div>

          <VisibleOn
            component={<FaqContactUs />}
            breakpoints={['md', 'lg', 'xl']}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={8}
          $style={{
            backgroundColor: theme.palette.common.white,
            position: 'relative',
            paddingTop: '1rem',
            borderRadius: '4px',
          }}
        >
          {loading && <Loading />}

          {(keyword || category) && (
            <div style={{ marginBottom: theme.spacing(2) }}>
              <Typography as="p">
                {`Filters: ${
                  selectedCategory ? selectedCategory.title : 'All categories'
                }, ${keyword === '' ? '' : `Filtered by ${keyword}`}`}
              </Typography>

              <Button
                variant="text"
                onClick={handleClearFilters}
                color="corporate"
              >
                clear
                <Icon
                  name="cross"
                  style={{
                    marginLeft: theme.spacing(1),
                  }}
                />
              </Button>
            </div>
          )}

          {filteredFaqs.map(({ question, answer }, i) => (
            <FAQ
              key={`faq${i + 1}`}
              headerEl={
                <FAQHeader>
                  <Typography color="ownership" weight="bold">
                    {question}
                  </Typography>
                </FAQHeader>
              }
              contentEl={
                <FAQContent>
                  <BBCode>{answer}</BBCode>
                </FAQContent>
              }
            />
          ))}
          <VisibleOn component={<FaqContactUs />} breakpoints={['xs', 'sm']} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Faqs
