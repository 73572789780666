/* eslint-disable */
import React, { useContext, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { components } from 'react-select'

import Theme from '../../../context/theme'
import Icon from '../icon'
import FormLabel from '../formlabel'

import NativeSelectRoot from './style'

/**
 * Native Select Component
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
const NativeSelect = ({
  children,
  ariaProps,
  placeholder,
  value,
  ...otherProps
}) => {
  const { theme } = React.useContext(Theme)

  return (
    <NativeSelectRoot
      {...otherProps}
      {...ariaProps}
      $as="select"
      $theme={theme}
    >
      {placeholder ? <option hidden>{placeholder}</option> : null}
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          role: 'option',
          'aria-selected': child.props.value === value,
        }),
      )}
    </NativeSelectRoot>
  )
}

const DropdownIndicator = ({ inheritColor, ...props }) => (
  <components.DropdownIndicator {...props}>
    <Icon
      name="caret-down"
      color={inheritColor ? 'currentColor' : 'text'}
      size={18}
    />
  </components.DropdownIndicator>
)

const IndicatorSeparator = () => null

/**
 * React Select Component
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
const CustomSelect = ({
  ariaProps,
  inheritColor,
  disabled,
  id,
  options,
  value,
  ...otherProps
}) => {
  const { theme } = useContext(Theme)
  const newProps = {
    ...otherProps,
    isDisabled: disabled,
    id: null,
  }

  const customStyles = useMemo(() => {
    const menuStyles = {
      ...theme.typography.base,
      color: theme.palette.text,
    }
    const inheritedStyles = inheritColor
      ? {
          color: 'inherit',
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: 0,
        }
      : {}

    const styles = {
      container: (base) => ({
        ...base,
        width: '100%',
      }),
      control: (base) => ({
        ...base,
        ...theme.typography.base,
        color: theme.palette.text,
        padding: '4px 0',
        ...inheritedStyles,
      }),
      placeholder: (base) => ({
        ...base,
        ...inheritedStyles,
      }),
      singleValue: (base) => ({ ...base, ...inheritedStyles }),
      menu: (base) => ({ ...base, ...menuStyles, zIndex: 1001 }),
      option: (provided, state) => {
        const backgroundColor = state.isFocused
          ? theme.palette.corporate
          : 'initial'
        return {
          ...provided,
          backgroundColor,
        }
      },
    }
    return styles
  }, [inheritColor, theme.palette.corporatePale, theme.palette.text, theme.typography.base])

  return (
    <ReactSelect
      {...newProps}
      options={options}
      isMulti={newProps.multiple}
      styles={customStyles}
      value={options.find((o) => o.value === value)}
      components={{ DropdownIndicator, IndicatorSeparator }}
      inputId={id ?? null}
      inputProps={{ ...ariaProps }}
    />
  )
};

/**
 *  Select Control
 *
 * @param {*} { children, native, options, rounded, fullWidth, helperText, ...otherProps }
 * @returns
 */
const Select = ({
  children,
  fullWidth,
  helperText,
  id,
  label,
  labelProps,
  multiple,
  name,
  native,
  onChange,
  options,
  required,
  rounded,
  ...otherProps
}) => {
  const fieldId = id ?? `select_${new Date().valueOf()}`;
  const labelId = `${fieldId}_label`

  const ariaProps = useMemo(() => {
    return {
      'aria-label': label,
      'aria-labelledby': label ? labelId : null,
      'aria-expanded': false,
      'aria-required': required || false,
    }
  }, [label, labelId, required]);

  const handleChange = useCallback(
    (event) => {
      if (native) onChange(event)
      else {
        let value = ''
        if (event && !multiple) value = event.value

        onChange({
          target: {
            id: fieldId,
            name: name,
            value,
            selectedOptions: (!multiple && event ? [event] : event || []).map(
              (option) => ({
                ...option,
                text: option.label,
              }),
            ),
          },
        })
      }
    },
    [native, onChange, fieldId, name, multiple],
  );

  return (
    <>
      {label ? (
        <FormLabel
          id={labelId}
          htmlFor={fieldId}
          fullWidth
          {...(labelProps || {})}
          style={{
            marginBottom: 8,
            ...(labelProps?.style || {}),
          }}
        >
          {label}
        </FormLabel>
      ) : null}
      {native ? (
        <NativeSelect
          $fullWidth={fullWidth}
          $rounded={rounded}
          ariaProps={{ ...ariaProps }}
          onChange={handleChange}
          {...otherProps}
        >
          {children}
        </NativeSelect>
      ) : (
        <CustomSelect
          ariaProps={ariaProps}
          id={fieldId}
          onChange={handleChange}
          options={options}
          {...otherProps}
        />
      )}
    </>
  )
};

/** Component Property Types */
NativeSelect.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  ariaProps: PropTypes.shape({
    ariaLabel: PropTypes.string,
    ariaLabelledby: PropTypes.string,
    ariaExpanded: PropTypes.bool,
    ariaRequired: PropTypes.bool,
  }).isRequired,
}

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    }),
  ),
  ariaProps: PropTypes.shape({
    'aria-label': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    'aria-expanded': PropTypes.bool,
    'aria-required': PropTypes.bool,
  }).isRequired,
  inheritColor: PropTypes.bool,
}

CustomSelect.defaultProps = { inheritColor: false, options: [] }

Select.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    }),
  ),
  native: PropTypes.bool,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  labelProps: PropTypes.object,
  onChange: PropTypes.func,
}

Select.defaultProps = {
  children: undefined,
  native: false,
  options: [],
  helperText: undefined,
  fullWidth: false,
  rounded: true,
  labelProps: {},
  onChange: () => { },
}

export default Select
