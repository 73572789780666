/* eslint-disable global-require */
/* eslint-env browser */
import Amplify from 'aws-amplify';
import {datadogLogger, initDatadogConsoleLogger} from "./utils/datadogLogger/logger";
import {isParkLeisureContext} from "./helpers/hostNameCheck";
import React from 'react';
import 'react-app-polyfill/ie11'; // For IE 11 support
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { BaseProvider, DefaultTheme, Pl2kTheme } from '@parkholidays/legacy_components';
import { Client as Styletron } from 'styletron-engine-atomic';
import { DebugEngine, Provider as StyletronProvider } from 'styletron-react';

import { ApolloProvider } from '@apollo/react-hooks';
import { apolloClient } from './services/api/Apollo';

import App from './App';
import Head from './components/Head/Head'
import './index.css';

import { AuthProvider } from './context/auth';
import { ParkProvider } from './context/park';
import { ThemeProvider } from './context/theme';
import { OWNERS_PARK_HOLIDAYS, OWNERS_PARK_LEISURE } from './constants'

initDatadogConsoleLogger()

const defaultDomain = isParkLeisureContext() ?  '.parkleisure.co.uk'  : '.parkholidays.com'
const domain = process.env.REACT_APP_COOKIE_DOMAIN || defaultDomain
const isRunningLocally = domain === 'localhost'
const debug = isRunningLocally ? new DebugEngine() : undefined

datadogLogger.log('isParkLeisureContext()',isParkLeisureContext())

// 1. Create a client engine instance
const engine = new Styletron()

if (isRunningLocally) {
  // eslint-disable-next-line import/no-extraneous-dependencies
  const axe = require('react-axe')
  axe(React, ReactDOM, 1000)
}

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-2',
    authenticationFlowType: 'USER_SRP_AUTH',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: isParkLeisureContext() ? 'eu-west-2_c9JDMAsNq' : 'eu-west-2_h7xryiZxh',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: isParkLeisureContext() ? '4fbb0c7mps1ft52v4r2k9elmic' : '2arps1b262ekog52bjuvc8n4eg',
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain,
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: 'lax', //"strict" |
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: !isRunningLocally
    },
  },
})

const app = (
  <HelmetProvider>
    <Head brand={isParkLeisureContext() ? OWNERS_PARK_LEISURE : OWNERS_PARK_HOLIDAYS} />
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <ThemeProvider>
        <BaseProvider theme={isParkLeisureContext() ? Pl2kTheme : DefaultTheme}>
          <ApolloProvider client={apolloClient}>
            <AuthProvider>
              <ParkProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </ParkProvider>
            </AuthProvider>
          </ApolloProvider>
        </BaseProvider>
      </ThemeProvider>
    </StyletronProvider>
  </HelmetProvider>
)

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate
renderMethod(app, document.getElementById('root'))
