import React from 'react'
import { Tag } from 'bbcode-to-react'

class OLTag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return <ol>{this.getComponents()}</ol>
  }
}

export default OLTag
