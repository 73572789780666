/** @flow */
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useStyletron } from 'styletron-react'

/** Components */
import Theme from '../../../context/theme'
import Anchor from '../anchor'
import AppbarRoot, { AppbarToolbar } from './styled'

export const AppbarBrand = props => {
  const [css] = useStyletron()

  const styles = css({
    lineHeight: 0,
    textDecoration: 'none',
    ...(props.style || {}),
  })

  return <Anchor {...props} color="inherit" className={styles} />
}

const appbarPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.oneOf([
    'default',
    'corporate',
    'holidays',
    'touring',
    'ownership',
    'error',
    'black',
    'white',
    'corporateDark',
    'pl2kBlue',
  ]),
  fluid: PropTypes.bool,
}

/**
 * Application Header Bar
 *
 * @param {*} props
 * @returns
 */
const Appbar = ({ children, color, fluid, ...otherProps }) => {
  const { theme } = useContext(Theme)

  return (
    <AppbarRoot role="banner" $color={color} $theme={theme} {...otherProps}>
      <AppbarToolbar $fluid={fluid} $theme={theme}>
        {children}
      </AppbarToolbar>
    </AppbarRoot>
  )
}

/** Component Property Types */
Appbar.propTypes = appbarPropTypes
Appbar.defaultProps = {
  color: 'corporate',
  fluid: false,
}

export default Appbar
