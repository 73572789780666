import React from 'react'
import { useMutation } from '@apollo/react-hooks'

import Container from '../../components/ui/container'
import CustomerCareForm from '../../components/customer-care/customer-care-form'
import Error from '../../components/ui/error'
import Icon from '../../components/ui/icon'
import ParkSelectToolbar from '../../components/parkSelectToolbar'
import Loading from '../../components/ui/loading'
import Typography from '../../components/ui/typography'

import Theme from '../../context/theme'
import SEND_COMPLAINT from './graphql'

const CustomerCare = () => {
  const { theme } = React.useContext(Theme)
  const [sendComplaint, { loading, error, data }] = useMutation(SEND_COMPLAINT)

  let formContent = <CustomerCareForm onSubmit={sendComplaint} />

  if (loading) {
    formContent = <Loading />
  }

  // If data has come back from the mutation, display a thank you message
  if (data && data.createCustomerCareEmail.success === true) {
    formContent = <p>Your enquiry has been received, thank you.</p>
  }

  return (
    <>
      <ParkSelectToolbar />
      <Container $style={{ marginTop: `${theme.spacing(2)}px` }}>
        <Typography
          as="h1"
          weight="bold"
          beforeEnhancer={
            <div
              style={{
                backgroundColor: '#4F4F4F',
                height: 25,
                width: 25,
                display: 'inline-block',
                borderRadius: '50%',
                textAlign: 'center',
                marginRight: theme.spacing(1),
              }}
            >
              <Icon name="oa-contactus" color="white" />
            </div>
          }
        >
          Customer Care
        </Typography>
        {error && <Error />}

        {formContent}
      </Container>
    </>
  )
}

export default CustomerCare
