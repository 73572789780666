import React, { useState } from 'react'
import { useStyletron } from 'styletron-react'

import Anchor from '../ui/anchor'
import Button from '../ui/button'
import CheckBox from '../ui/checkbox'
import FormField from '../ui/formfield'
import FormGroup from '../ui/formgroup'
import FormLabel from '../ui/formlabel'
import Typography from '../ui/typography'

import AuthContext from '../../context/auth'
import ParkContext from '../../context/park'
import Theme from '../../context/theme'
import { OWNERSHIP } from 'src/constants'

const CustomerCareForm = props => {
  const { theme } = React.useContext(Theme)
  const { park } = React.useContext(ParkContext)
  const { user } = React.useContext(AuthContext)

  const [message, setMessage] = useState('')
  const [privacyCheck, setPrivacyCheck] = useState(false)

  const [css] = useStyletron()
  const submitButtonStyles = { marginTop: theme.spacing(2) }
  const textareaStyles = { resize: 'vertical' }

  // Find customer numbers (will depend on the currently selected park)
  const reference = user && user.customerNosByPark
    ? user.customerNosByPark.find(item => {
        return Number(item.parkId) === Number(park.id)
      }).customerNo
    : null

  return (
    <>
      <Typography as="p">
        If you are unhappy with any aspect of your {theme.isPl2k ? "Park Leisure" : "Park Holidays"} experience
        please complete the form below and we will get back to you in due
        course.
      </Typography>

      <form
        onSubmit={e => {
          e.preventDefault()
          props.onSubmit({
            variables: {
              input: {
                source: 'owners.parkholidays.com',
                reference,
                description: message,
              },
            },
          })
        }}
      >
        <FormGroup>
          <FormField
            label="Message"
            multiline
            fullWidth
            rows={6}
            className={css(textareaStyles)}
            required
            onChange={e => setMessage(e.target.value)}
            value={message}
          />
        </FormGroup>

        <FormLabel
          control={
            <CheckBox
              onChange={e => setPrivacyCheck(e.target.checked)}
              value={privacyCheck}
              required
            />
          }
        >
          I have read and accept the{' '}
          <Anchor native href="/privacy-policy" target="_blank">
            Privacy Policy
          </Anchor>
        </FormLabel>
        <div>
        <Button color={OWNERSHIP} className={css(submitButtonStyles)}>
            Submit
          </Button>
        </div>
      </form>
    </>
  )
}

export default CustomerCareForm
