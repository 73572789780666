import { gql } from 'apollo-boost';

const GET_PARKS = gql`
  query allParks($filters: [Filter]) {
    allParks(filters: $filters) {
      id
      code
      name
      sortOrder
      logo {
        path
        alternateText
      }
    }
  }
`;

export default GET_PARKS;
