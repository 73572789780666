/*
  DO NOT MODIFY THIS FILE
*/
import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from '../styles'
import Shimmer from '../shimmer/Shimmer'
import WithLoading from '../with-loading/WithLoading'

const propTypes = {
  // aspectRatio OR height, not both
  aspectRatio: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  containerRef: PropTypes.func,
  crossOrigin: PropTypes.bool,
  // aspectRatio OR height, not both
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  importance: PropTypes.oneOf(['high', 'low']),
  loadPrimaryImage: PropTypes.bool,
  loading: PropTypes.oneOf(['lazy', 'eager']),
  objectFit: PropTypes.oneOf(['cover', 'contain', 'scale-down']),
  objectPosition: PropTypes.string,
  onLoad: PropTypes.func,
  previewEncodedPNG: PropTypes.string,
  sizes: PropTypes.string,
  srcSet: PropTypes.string,
  // aspectRatio OR width, not both
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const defaultProps = {
  aspectRatio: null,
  borderRadius: undefined,
  containerRef: () => {},
  crossOrigin: undefined,
  height: null,
  id: undefined,
  importance: undefined,
  loadPrimaryImage: true,
  loading: undefined,
  objectFit: 'cover',
  objectPosition: undefined,
  onLoad: () => {},
  previewEncodedPNG: null,
  sizes: undefined,
  srcSet: undefined,
  width: null,
}

const SHIMMER_STATES = {
  none: 'none',
  removing: 'removing',
  visible: 'visible',
}

// Base styles can be overridden by
// using the style attribute as an
// object with the keys defined below
const styles = {
  imageContainer: {
    position: 'relative',
  },
  imagePositioner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  shimmerContainer: {
    // background: e.color.white,
    bottom: 0,
    left: 0,
    opacity: 1,
    position: 'absolute',
    right: 0,
    top: 0,
    transition: '300ms opacity ease-out',
  },
  shimmerContainer_fadeOut: {
    opacity: 0,
  },
  backgroundImage: {
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
  },
  removeDivExceptInIE: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    height: '100%',
    width: '100%',
    '@supports (object-fit: cover)': {
      display: 'none',
      backgroundImage: 'none',
    },
  },
  img: {
    height: 0,
    width: 0,
    position: 'absolute',
    '@supports (object-fit: cover)': {
      height: '100%',
      width: '100%',
      position: 'static',
    },
  },
  img__autoHeightOverride: {
    height: '100%',
    width: '100%',
    position: 'static',
  },
}

const Image = ({
  alt,
  aspectRatio,
  borderRadius,
  className,
  containerRef,
  crossOrigin,
  decoding,
  height,
  id,
  importance,
  isLoading,
  loadPrimaryImage,
  loading,
  objectFit,
  objectPosition,
  onLoad,
  previewEncodedPNG,
  sizes,
  src,
  srcSet,
  width,
}) => {
  const [css] = useStyletron()
  const [state, setState] = React.useState({
    hasPrimaryImageLoaded: false,
    shimmerState: isLoading ? SHIMMER_STATES.visible : SHIMMER_STATES.none,
  })
  let imageRef = null

  // React.useEffect(() => {
  //   // componentDidMount && componentWillReceiveProps
  //   setState(prevState => ({
  //     ...prevState,
  //     shimmerState: isLoading
  //       ? SHIMMER_STATES.visible
  //       : SHIMMER_STATES.removing,
  //   }))

  //   return () => {
  //     // componentWillUnmount
  //   }
  // }, [isLoading, state.shimmerState])

  const handleImageLoad = () => {
    state.hasPrimaryImageLoaded ||
      (onLoad(),
      setState(prevState => ({
        ...prevState,
        hasPrimaryImageLoaded: true,
      })))
  }

  const imagePositionWrapper = children => {
    return aspectRatio
      ? React.createElement(
          'div',
          {
            className: css({
              ...styles.absoluteFill,
              ...styles.imagePositioner,
            }),
          },
          children,
        )
      : children
  }

  const setImageRef = ref => {
    imageRef = ref
    // imageInstrumentation = {
    //   imageRef,
    //   imageInstrumentation,
    //   src || ""
    // }
    hasPrimaryImageLoaded ||
      (imageRef && imageRef.complete && handleImageLoad())
  }

  const { hasPrimaryImageLoaded, shimmerState } = state
  const heightProperty = height || 'auto'
  const isAutoHeight = !aspectRatio && 'auto' === heightProperty
  const isShimmering = shimmerState !== SHIMMER_STATES.none
  const accessibleProps = isLoading
    ? {
        role: 'img',
        'aria-busy': true,
        'aria-label': 'Image is loading',
      }
    : alt &&
      alt.trim() && {
        role: 'img',
        'aria-busy': false,
        'aria-label': alt,
      }

  return (
    <div
      className={css({
        ...(aspectRatio && styles.imageContainer),
        ...(aspectRatio && {
          paddingTop: `${(100 * aspectRatio).toFixed(4)}%`,
        }),
        ...(!aspectRatio && {
          display: 'inline-block',
          verticalAlign: 'bottom',
          height: height || 'auto',
          width: width || 'auto',
          minHeight: '1px',
        }),
        ...styles.backgroundImage,
        ...(previewEncodedPNG &&
          !(hasPrimaryImageLoaded && objectFit !== 'contain') && {
            backgroundImage: `url('data:image/png;base64,${previewEncodedPNG}')`,
            backgroundSize: objectFit,
          }),
      })}
      ref={containerRef}
      {...accessibleProps}
    >
      {loadPrimaryImage &&
        imagePositionWrapper(
          <React.Fragment>
            <img
              className={css({
                ...(aspectRatio && styles.absoluteFill),
                ...styles.img,
                ...(isAutoHeight && styles.img__autoHeightOverride),
                ...(!isAutoHeight && {
                  objectFit,
                  objectPosition,
                }),
                ...(!aspectRatio && {
                  verticalAlign: 'bottom',
                }),
                ...(borderRadius && {
                  borderRadius:
                    typeof borderRadius === 'number'
                      ? `${borderRadius}px`
                      : borderRadius,
                }),
              })}
              {...{
                'aria-hidden': true,
                alt,
                crossOrigin: crossOrigin ? 'anonymous' : undefined,
                decoding,
                importance,
                id,
                loading,
                onLoad: handleImageLoad,
                ref: e => setImageRef(e),
                sizes,
                src: src ? src : undefined,
                srcSet,
              }}
            />
            {!isAutoHeight && (
              <div
                className={css({
                  ...styles.removeDivExceptInIE,
                  ...(aspectRatio && styles.absoluteFill),
                  ...styles.backgroundImage,
                  ...{
                    backgroundImage: `url('${src}')`,
                    backgroundSize: objectFit,
                  },
                  ...(borderRadius && {
                    borderRadius:
                      typeof borderRadius === 'number'
                        ? `${borderRadius}px`
                        : borderRadius,
                  }),
                })}
              ></div>
            )}
          </React.Fragment>,
        )}
      {isShimmering && (
        <div
          className={css({
            ...styles.shimmerContainer,
            ...(shimmerState === SHIMMER_STATES.removing &&
              styles.shimmerContainer_fadeOut),
          })}
          {...{
            onTransitionEnd: () => {
              setState({
                shimmerState: SHIMMER_STATES.none,
              })
            },
          }}
        >
          <Shimmer block width="100%" height="100%" />
        </div>
      )}
    </div>
  )
}

Image.propTypes = propTypes
Image.defaultProps = defaultProps

export default WithLoading(Image)
