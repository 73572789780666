export default function(text, length) {
  var n = text.slice(0, length),
    r = n.lastIndexOf(" "),
    o = length - r,
    a = Math.ceil(0.1 * length);
  return (
    " " !== text.charAt(length) && o <= a && 0 < r && (n = n.slice(0, r)),
    n.replace(/\.$/, "").concat("…")
  );
}
