import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useStyletron } from '@parkholidays/legacy_components'

import Container from '../../components/ui/container'
import Loading from '../../components/ui/loading'
import Header from './components/Header'
import Summary from './components/Summary'
import Details from './components/Details'

import GET_EVENT_BY_SLUG from './graphql'

const Event = props => {
  const [css] = useStyletron()
  const { eventSlug } = props.match.params

  const { loading, error, data } = useQuery(GET_EVENT_BY_SLUG, {
    variables: {
      slug: eventSlug,
    },
  })

  if (loading) return <Loading />
  if (error) return <p>Managers Message error</p>
  const {
    eventBySlug: {
      title,
      media,
      startDate,
      endDate,
      location,
      type,
      description,
      cancelled,
    },
  } = data
  const contentStackImageSrc = media && media.path && media.path.includes('contentstack') ? media.path : null;

  return (
    <Container>
      <Header
        title={title}
        media={
          media && {
            src: contentStackImageSrc ?? `https://assets.parkholidays.com/1300x520/assets/${media.path}`,
            alt: media.alternateText,
          }
        }
        isCancelled={cancelled}
      />

      <div
        className={css({
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          backgroundColor: 'white',
          '@media all and (min-width: 40em)': {
            flexWrap: 'nowrap',
          },
        })}
      >
        <Summary
          startDate={startDate}
          endDate={endDate}
          location={location}
          type={type.name}
        />

        <Details description={description} />
      </div>
    </Container>
  )
}

export default Event
