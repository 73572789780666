/* eslint-disable import/prefer-default-export */
import { styled } from 'styletron-react'

const Seperator = styled('div', {
  conent: '""',
  display: 'inline-block',
  width: '0px',
  height: '12px',
  borderTop: '0',
  borderBottom: '0',
  borderRight: '0',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#fff',
  margin: '0 8px',
})

export default Seperator
