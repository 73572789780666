import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from '@parkholidays/legacy_components'
import Text from '@parkholidays/legacy_components/dist/text/Text'
// import Icon from '../../../components/ui/icon'

const propTypes = {
  message: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
}

const ImportantMessage = ({ message: { title, content } }) => {
  const [css, theme] = useStyletron()

  return (
    <div
      className={css({
        backgroundColor: theme.palette.error,
        color: theme.palette.common.white,
        paddingTop: '1rem',
        paddingRight: '1rem',
        paddingBottom: '1rem',
        paddingLeft: '1rem',
        marginLeft: '-1rem',
        marginRight: '-1rem',
        '@media all and (min-width: 36em)': {
          borderRadius: '4px',
          marginLeft: '0',
          marginRight: '0',
          boxShadow: theme.lighting.shadow500,
        },
      })}
    >
      {/* <Icon name="warning-o" size={24} $style={{ float: 'right' }} /> */}
      <Text weight="bolder" color="white" $style={{ marginBottom: '0.5rem' }}>
        {title}
      </Text>
      <Text color="white">{content}</Text>
    </div>
  )
}

ImportantMessage.propTypes = propTypes

export default ImportantMessage
