import React from 'react';
import PropTypes from 'prop-types';
import ReactImage from 'react-image';
import ImagePlaceHolder from '../imagePlaceHolder'

const styles = {
  maxWidth: '100%',
};

const Image = ({ src, alt, style, ...otherProps }) => {

  if (!src || src.includes('undefined')) {
    return (
      <ImagePlaceHolder styleProps={otherProps}/>  
    )
  };

  return (
    <ReactImage
      src={src}
      alt={alt}
      {...otherProps}
      style={{ ...styles, ...(style || {}) }}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;
