/* eslint-disable import/prefer-default-export */
import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { Auth as AmplifyAuth, Hub } from 'aws-amplify'

// cached storage for the user token
let token
const headers = jwtToken => ({
  headers: {
    authorization: `bearer ${jwtToken}`,
    consumer: process.env.REACT_APP_CONSUMER_TOKEN,
  },
})

const withHeaders = setContext(() => {
  // if you have a cached value, return it immediately
  if (token && token === localStorage.getItem('jwtToken'))
    return { ...headers(token) }

  return AmplifyAuth.currentSession().then(data => {
    token = data.idToken.jwtToken
    Hub.dispatch('auth', {
      event: 'tokenChanged',
      data: token,
      message: '',
    })

    return { ...headers(token) }
  })
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    const statusCode = networkError.statusCode
    // If networkError is unauthorised or networkError is bad request plus graphql errors
    // contains api token expired, clear token then sign out user.
    if (
      statusCode === 401 ||
      (statusCode === 400 &&
        graphQLErrors.find(
          ({ message }) =>
            message === 'Your api token has expired. Please login again.',
        ))
    ) {
      token = null
      AmplifyAuth.signOut()
        .then(() =>
          Hub.dispatch('auth', {
            event: 'signOut',
          }),
        )
        .catch(exception => console.log(exception))
    }
  }
})

const authFlowLink = withHeaders.concat(errorLink)
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URI })

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const apolloClient = new ApolloClient({
  link: authFlowLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions,
})

export { apolloClient }
