import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../components/ui/icon'
import { FAQHeaderWrapper } from './styled-components'

const propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool,
  onClick: PropTypes.func,
}

const FAQHeader = ({ children, open, onClick }) => (
  <FAQHeaderWrapper onClick={onClick}>
    {children}
    <Icon name={open ? 'chevron-up' : 'chevron-down'} />
  </FAQHeaderWrapper>
)

FAQHeader.propTypes = propTypes
FAQHeader.defaultProps = { open: false, onClick: undefined }

export default FAQHeader
