import React, { Suspense, lazy } from 'react'
import { useQuery } from '@apollo/react-hooks'
import ErrorBoundary from './ErrorBoundary'
import Loader from '../../components/ui/loading'
import GET_PAGE from './graphql'

// Declare all Templates as setup in the Page Template
// database here....
const TEMPLATES = {
  test: {
    component: lazy(() => import('./templates/test-offer')),
  },
  list: {
    component: lazy(() => import('./templates/list-offer')),
  },
}

const SpecialOfferRouter = props => {
  const { loading, error, data } = useQuery(GET_PAGE, {
    variables: {
      filters: [
        {
          column: 'tenant_id',
          operator: '=',
          value: process.env.REACT_APP_OWNERS_AREA_TENANT_ID,
        },
        {
          column: 'url',
          operator: '=',
          value: `/${props.match.params.slug}`,
        },
      ],
    },
  })

  if (loading) {
    return <Loader />
  }

  if (error) {
    return (
      <div>
        Unfortunately we couldn&apos;t load this special offer, please try again
        later.
      </div>
    )
  }

  if (data) {
    const DynamicComponent =
      TEMPLATES[data.allPages[0].pageTemplate.name].component

    const pageData = JSON.parse(atob(data.allPages[0].data))

    return (
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <DynamicComponent {...pageData} />
        </Suspense>
      </ErrorBoundary>
    )
  }
}

export default SpecialOfferRouter
