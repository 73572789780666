export const GRAPHQL_API_URI = process.env.REACT_APP_APOLLO_API_ENDPOINT;
//trigger a deploy
export const MANAGERS_MESSAGE_TYPE_ID = 1;

export const ASSETS_PREFIX = 'https://assets.parkholidays.com/';

export const MY_BRAND_URLS = {
  myParkholidaysURL:
    process.env.REACT_APP_MY_PARKHOLIDAYS_URL || 'https://my.parkholidays.com',
  myParkleisureURL:
    process.env.REACT_APP_MY_PARKLEISURE_URL || 'https://my.parkleisure.co.uk',
};

export const PH_PRIVACY_POLICY = `${process.env.REACT_APP_PH_BASE_URL}/privacy-policy` || 'https://www.parkholidays.com/privacy-policy'; 

export const PL_PRIVACY_POLICY = `${process.env.REACT_APP_PL_BASE_URL}/privacy-policy` || 'https://www.parkleisure.co.uk/privacy-policy';

export const  PL_TNC =  `${process.env.REACT_APP_PL_BASE_URL}/terms-and-conditions` || "https://www.parkleisure.co.uk/terms-and-conditions";
