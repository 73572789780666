import { styled } from 'styletron-react'

export const FAQWrapper = styled('article', ({ $backgroundColor }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  backgroundColor: $backgroundColor ? $backgroundColor : 'transparent',
  marginBottom: '1rem',
  borderRadius: '4px',
}))

export const FAQHeaderWrapper = styled('header', {
  boxSizing: 'border-box',
  position: 'relative',
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  padding: '1rem',
  cursor: 'pointer',
})

export const FAQContentWrapper = styled('main', {
  boxSizing: 'border-box',
  position: 'relative',
  paddingRight: '1rem',
  paddingBottom: '1rem',
  paddingLeft: '1rem',
})
