import { withStyle } from 'styletron-react';
import BaseTypography from '../typography/style';

const HrTitleRoot = withStyle(BaseTypography, ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  ':before': {
    content: '""',
    borderTop: '2px solid',
    marginRight: `${$theme.spacing(1)}px`,
    flexGrow: 1,
  },
  ':after': {
    content: '""',
    borderTop: '2px solid',
    marginLeft: `${$theme.spacing(1)}px`,
    flexGrow: 1,
  },
}));

export default HrTitleRoot;
