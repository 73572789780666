/** @flow */
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import Theme from '../../../context/theme'
import AnchorRoot from './styled'

const anchorPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  native: PropTypes.bool,
  href: PropTypes.string,
  color: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
}

/**
 * Anchor
 *
 * @param {*} props
 * @returns
 */
const Anchor = ({
  children,
  native,
  color,
  startIcon,
  endIcon,
  ...otherProps
}) => {
  const { theme } = React.useContext(Theme)
  const margin = React.Children.count(children) > 0 ? theme.spacing(1) : 0
  const url = otherProps.to || otherProps.href || '/'
  const isExternal = url.startsWith('http')

  // console.log('External?', url, isExternal);

  return (
    <AnchorRoot
      {...otherProps}
      $as={native ? 'a' : Link}
      to={isExternal ? { pathname: url } : url}
      href={isExternal ? url : null}
      $color={color}
      $theme={theme}
      target={isExternal ? '_blank' : null}
    >
      {startIcon
        ? React.cloneElement(startIcon, {
            style: { marginRight: margin, marginBottom: 2 },
          })
        : null}
      {children}
      {endIcon
        ? React.cloneElement(endIcon, {
            style: { marginLeft: margin, marginBottom: 2 },
          })
        : null}
    </AnchorRoot>
  )
}

/** Component Property Types */
// eslint-disable-next-line react/no-typos
Anchor.propTypes = anchorPropTypes
Anchor.defaultProps = {
  children: undefined,
  native: false,
  href: undefined,
  color: 'default',
  startIcon: undefined,
  endIcon: undefined,
}

export default Anchor
