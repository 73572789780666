import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@parkholidays/legacy_components'
import ResourceHeader from './ResourceHeader'
import ResourceContent from './ResourceContent'
import ResourceFooter from './ResourceFooter'
import { formatDate } from '../../../../helpers/formatDate'

const dateFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

const propTypes = {
  resource: PropTypes.shape({
    // id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    // meta: PropTypes.shape({
    //   assetCategoryId: PropTypes.number.isRequired,
    //   order: PropTypes.number.isRequired,
    // }),
  }),
  categoryName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}
const defaultProps = {}

const Resource = ({ asset, className, categoryName }) => {
  const {
    // id,
    title,
    description,
    path,
    size,
    createdAt,
    // meta: { assetCategoryId, order },
    media,
  } = asset

  return (
    <article className={className}>
      <ResourceHeader
        category={categoryName}
        addedDate={formatDate(createdAt, dateFormatOptions)}
      />
      <ResourceContent title={title} description={description} media={media} />
      <ResourceFooter size={size} path={path} />
    </article>
  )
}

Resource.propTypes = propTypes
Resource.defaultProps = defaultProps

export default styled(Resource, ({ $theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  borderRadius: '4px',
  backgroundColor: $theme.palette.common.white,
  boxShadow: $theme.lighting.shadow500,
  marginBottom: '1rem',
}))
