const isParkLeisureContext = () => {
  const allPlDomains = ["owners-staging-pl.parkholidays.com","owners.parkleisure.co.uk", "owners2.parkleisure.co.uk"]
  const isPL = allPlDomains.includes(window.location.hostname)
  console.log('ISPLCONTEXT', isPL)
  return isPL
}

const isStagingDomain = () => {
  const stagingDomains = ["owners-staging-pl.parkholidays.com","owners-staging-ph.parkholidays.com"]
  const isStaging = stagingDomains.includes(window.location.hostname)
  console.log('ISSTAGING', isStaging)
  return isStaging
}

export {isParkLeisureContext, isStagingDomain}
