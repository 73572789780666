import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Theme from '../../../context/theme';

import { FAQWrapper } from './styled-components';

const propTypes = {
  headerEl: PropTypes.node.isRequired,
  contentEl: PropTypes.node.isRequired,
};

const FAQ = ({ headerEl, contentEl }) => {
  const [open, setOpen] = React.useState(false);
  const {
    theme: { isPl2k },
  } = useContext(Theme);
  const bgColor = open && !isPl2k ? '#F4E8EF' :'rgb(243, 246, 249)';

  return (
    <FAQWrapper $backgroundColor={bgColor}>
      {React.cloneElement(headerEl, { open, onClick: () => setOpen(!open) })}
      {React.cloneElement(contentEl, { open })}
    </FAQWrapper>
  );
};

FAQ.propTypes = propTypes;

export default FAQ;
