import { gql } from 'apollo-boost'

export default gql`
  query($criteria: EventCriteria) {
    allEvents(criteria: $criteria) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
          description
          startDate
          endDate
          pinned
          slug
          cancelled
          parkId
          type {
            id
            name
          }
          category {
            id
            name
            media {
              id
              path
            }
          }
          eventTypeId
          parkId
          deletedAt
          media {
            id
            alternateText
            path
          }
        }
      }
    }
  }
`
