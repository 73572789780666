/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import { useStyletron } from 'styletron-react'

import IconWithDirection from '@parkholidays/legacy_components/dist/icon/IconWithDirection'

import Theme from '../../../context/theme'

import { Fab } from '../button'
import CarouselRoot, { Wrapper, Dot, Track, Item, NEXT, PREV } from './styled'

const initialState = { pos: 0, sliding: false, dir: NEXT, interacted: false }

/**
 * Reducer
 *
 * @param {*} state
 * @param {*} { type, pos, targetPos }
 * @returns
 */
const reducer = (state, { type, pos }) => {
  switch (type) {
    case 'reset':
      return initialState
    case PREV:
    case NEXT:
      return {
        ...state,
        dir: type,
        sliding: true,
        pos,
        interacted: true,
      }
    case 'stopSliding':
      return { ...state, sliding: false }
    default:
      return state
  }
}

const getOrder = ({ index, pos, numItems }) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos
}

const navPropTypes = {
  right: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

/**
 *  Navigation Button
 *
 * @param {*} { right, onClick }
 * @returns
 */
const Nav = ({ right, onClick, ...otherProps }) => {
  const [css] = useStyletron()
  const styles = css({
    position: 'absolute',
    top: '50%',
    // marginTop: '-17px',
    left: right ? 'auto' : 0,
    right: right ? 0 : 'auto',
  })

  return (
    <Fab
      {...otherProps}
      raised
      color="white"
      size="small"
      className={styles}
      onClick={() => onClick(right ? NEXT : PREV)}
    >
      <IconWithDirection
        viewBox={`-${right ? 4 : 2} -3 24 24`}
        direction={right ? 'right' : 'left'}
        className={css({ width: 18, height: 18 })}
      />
    </Fab>
  )
}

const dotsPropTypes = {
  activeIndex: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  align: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

/**
 * Dots
 *
 * @param {*} { count, activeIndex, align, onClick }
 * @returns
 */
const Dots = ({ count, activeIndex, align, onClick }) => {
  const { theme } = React.useContext(Theme)
  const [css] = useStyletron()

  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: align,
        marginBottom: theme.spacing(2),
      })}
    >
      {[...new Array(count)].map((_, index) => (
        <div
          key={btoa(index)}
          className={css({
            cursor: 'pointer',
            padding: theme.spacing(1),
          })}
          onClick={activeIndex !== index ? () => onClick(index) : null}
        >
          <Dot $active={activeIndex === index} $theme={theme} />
        </div>
      ))}
    </div>
  )
}

const carouselPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

/**
 * React Carousel
 *
 * @param {*} { children }
 * @returns
 */
const Carousel = ({ children, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [isMobile, setIsMobile] = React.useState(false)
  const numItems = React.Children.count(children)

  const updateStateByDevice = () =>
    setIsMobile(window.innerWidth < theme.breakpoints.width('sm'))

  const slide = dir => {
    let pos

    switch (dir) {
      case PREV:
        pos =
          state.pos === 0
            ? numItems - (isMobile ? 1 : 2)
            : state.pos - (isMobile ? 1 : 2)
        break
      case NEXT:
        pos =
          state.pos === numItems - (isMobile ? 1 : 2)
            ? 0
            : state.pos + (isMobile ? 1 : 2)
        break
      default:
        pos = 0
        break
    }

    dispatch({ type: dir, pos })
    setTimeout(() => dispatch({ type: 'stopSliding' }), 50)
  }

  const handleDotClicked = index => {
    let dir = index > state.pos ? NEXT : PREV
    if (state.pos === numItems - 1 && index === 0) dir = NEXT
    if (state.pos === 0 && index === numItems - 1) dir = PREV

    dispatch({ type: dir, pos: index })
    setTimeout(() => dispatch({ type: 'stopSliding' }), 50)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  React.useEffect(() => {
    updateStateByDevice()
    window.addEventListener('resize', updateStateByDevice)
    // () => window.removeEventListener('resize', updateStateByDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper {...handlers} $isMobile={isMobile} $theme={theme}>
      {numItems > 1 && !isMobile
        ? [false, true].map(right => (
            <Nav key={right.toString()} right={right} onClick={slide} />
          ))
        : null}

      {numItems > 1 && (
        <Dots
          activeIndex={isMobile ? state.pos : Math.ceil(state.pos / 2)}
          count={isMobile ? numItems : Math.ceil(numItems / 2)}
          align={isMobile ? 'flex-end' : 'center'}
          onClick={dot => handleDotClicked(dot)}
        />
      )}

      <CarouselRoot>
        <Track
          {...otherProps}
          $isMobile={isMobile}
          $dir={state.dir}
          $sliding={state.sliding}
          $count={React.Children.length}
          $interacted={state.interacted}
        >
          {React.Children.map(children, (child, index) => (
            <Item
              key={index}
              $order={getOrder({ index, pos: state.pos, numItems })}
              $isMobile={isMobile}
            >
              {child}
            </Item>
          ))}
        </Track>
      </CarouselRoot>
    </Wrapper>
  )
}

/* Carousel Component PropTypes */
Carousel.propTypes = carouselPropTypes
Nav.propTypes = navPropTypes
Nav.defaultProps = {
  right: false,
}

Dots.propTypes = dotsPropTypes
export default Carousel
