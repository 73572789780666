import { styled } from 'styletron-react';

// eslint-disable-next-line import/prefer-default-export
export const ParkWrapper = styled('div', {
  boxSizing: 'border-box',
  height: '38px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});
