import Markdown from './markdown'
import PageNotFound from './error/PageNotFound'
import NoPark from './error/NoPark'
import MigrationNotice from './migration-notice/Migration'
import OwnershipChange from './ownershipChange-notice/OwnershipChange'
import Login, {
  Logout,
  Register,
  ForgotPassword,
  PasswordReset,
  VerifyAccount,
} from './auth'

import Dashboard from './dashboard'
import ContactUs from './contact-us'
import CustomerCare from './customer-care'
import Faqs from './faqs'
import Events from './events'
import Event from './event'

import ManagersMessage from './managers-message'
import InformationResources from './information-resources'
import SpecialOffers from './special-offers'
import SpecialOfferRouter from './special-offer'

export {
  NoPark,
  Login,
  Logout,
  Register,
  ForgotPassword,
  PasswordReset,
  VerifyAccount,
  Markdown,
  Dashboard,
  ContactUs,
  CustomerCare,
  Faqs,
  Events,
  Event,
  ManagersMessage,
  InformationResources,
  SpecialOffers,
  SpecialOfferRouter,
  MigrationNotice,
  OwnershipChange,
}

export default PageNotFound
