import React from 'react'
import Card, { CardContent } from '../../components/ui/card'
import Typography from '../../components/ui/typography'

import { ContentRoot } from './styled'


const OwnershipChange = () => {

  return (
  <ContentRoot>
    <Card
      color="white"
      style={{
        maxWidth: 400,
        border: '3 px solid #08375F',
        outline: '0 0 0 white',
      }}
      elevation={5}
    >
      <CardContent>
        <Typography as="h1" weight="bold" align="center">
          Ownership change notice
        </Typography>
        <Typography as="p" noMargins align="center">
          There has been a change in ownership of Littondale Holiday Park. Please contact the management team at Green Wood Parks Limited, the new owners of the park, for any enquiries.
        </Typography>
      </CardContent>
    </Card>
  </ContentRoot>
)}

export default OwnershipChange
