const footerStyles = ({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  backgroundColor: theme.palette.corporate,
  color: theme.palette.common.white,
})

const containerStyles = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
}

const logoWrapperStyles = {
  display: 'none',
  '@media all and (min-width: 40em)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const textWrapperStyles = ({ theme }) => ({
  margin: `0px ${theme.spacing(2)}px`,
})

const companyTextStyles = { display: 'inline ' }
const privacyPolicyLinkStyles = { textDecoration: 'none' }

export default props =>
  Object.freeze({
    footerStyles: footerStyles(props),
    containerStyles,
    logoWrapperStyles,
    textWrapperStyles: textWrapperStyles(props),
    companyTextStyles,
    privacyPolicyLinkStyles,
  })
