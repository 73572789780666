import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from '@parkholidays/legacy_components'

import { LinkButton } from '../../components/ui/button'
import Icon from '../../components/ui/icon'
import VisibleOn, { HiddenOn } from '../../components/hoc/visible-on'

import styles from './styles'

const contactUsLinkProptypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
}

/**
 * My Account Button
 *
 */
const ContactUsLink = ({ color, onClick, ...otherProps }) => {
  const [css] = useStyletron()
  const { linkButtonStyles } = styles()

  return (
    <>
      <HiddenOn
        component={
          <LinkButton
            to="/contact-us"
            rounded={false}
            size="small"
            color={color}
            aria-label="contact us"
            endIcon={<Icon name="oa-contactus" size={24} color="white" />}
            className={css(linkButtonStyles)}
          >
            CONTACT US
          </LinkButton>
        }
        breakpoints={['xs', 'sm']}
        {...otherProps}
      />

      <VisibleOn
        component={
          <LinkButton
            size="small"
            to="/contact-us"
            rounded={false}
            color={color}
            aria-label="contact us"
            endIcon={<Icon name="oa-contactus" size={24} color="white" />}
            className={css(linkButtonStyles)}
          ></LinkButton>
        }
        breakpoints={['xs', 'sm']}
        {...otherProps}
      />
    </>
  )
}

ContactUsLink.propTypes = contactUsLinkProptypes
ContactUsLink.defaultProps = {
  color: undefined,
  onClick: undefined,
}

export default ContactUsLink
