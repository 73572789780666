import { gql } from 'apollo-boost';

const GET_ALL_CONTACTS = gql`
  query($criteria: ParkContactCriteria) {
    allParkContacts(criteria: $criteria) {
      id
      firstname
      surname
      email
      phoneNo
      mobilePhoneNo
      jobRole {
        id
        jobTitle
      }
      media {
        id
        path
        alternateText
      }
    }
  }
`;

export default GET_ALL_CONTACTS;
