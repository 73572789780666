import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import { useStyletron, ThemeContext } from '@parkholidays/legacy_components'
import { formatDate } from '../../../helpers/formatDate'
import useSideHeadingColor from '../../../context/hooks/useSideHeadingColor'
import useLineColor from '../../../context/hooks/useLineColor'

const formatTimeOptions = {
  hour: 'numeric',
  hour12: true,
  minute: 'numeric',
}

const formatDateOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
}

const propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

const Details = ({ startDate, endDate, location, type }) => {
  const [css] = useStyletron()
  const lineHeaderColor =  useSideHeadingColor()
  const lineColor = useLineColor();
  const {spacing} = React.useContext(ThemeContext)

  const styles = useMemo(()=> {
    return {
    summaryContainer: {
      padding: `${spacing(2)}px`,
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: '100%',
      '@media all and (min-width: 40em)': {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '250px',
      },
    },
    lineBlock: {
      marginBottom: `${spacing(2)}px`,
    },
    lineHeader: {
      display: 'inline',
      fontWeight: 600,
      color: lineHeaderColor,
      '@media all and (min-width: 40em)': {
        display: 'block',
      },
    },
    lineContent: {
      display: 'inline-block',
      color: lineColor,
    },
  }
}, [lineColor, lineHeaderColor, spacing])

  return (
    <div className={css(styles.summaryContainer)}>
      <div className={css(styles.lineBlock)}>
        <div className={css(styles.lineHeader)}>{'When: '}</div>
        <div className={css(styles.lineContent)}>{`${formatDate(
          startDate,
          formatDateOptions,
        )} - ${formatDate(endDate, formatDateOptions)}`}</div>
        <div
          className={css(styles.lineContent, {
            display: 'list-item',
            listStylePosition: 'inside',
          })}
        >{`${formatDate(startDate, formatTimeOptions)} - ${formatDate(
          endDate,
          formatTimeOptions,
        )}`}</div>
      </div>

      <div className={css(styles.lineBlock)}>
        <div className={css(styles.lineHeader)}>{'Where: '}</div>
        <div className={css(styles.lineContent)}>{location}</div>
      </div>

      <div className={css(styles.lineBlock)}>
        <div className={css(styles.lineContent)}>{type}</div>
      </div>
    </div>
  )
}

Details.propTypes = propTypes

export default Details
