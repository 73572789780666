import { styled } from '@parkholidays/legacy_components'

export const EventGrid = styled('section', {
  display: 'flex',
  flexWrap: 'wrap',
})

export const EventGridItem = styled('article', {
  display: 'flex',
  width: '100%',
  flexShrink: 1,
  flexGrow: 1,
  flexBasis: '100%',
  marginTop: '1rem',
  marginBottom: '1rem',
  '@media screen and (min-width: 48.75em)': {
    flexBasis: 'calc(100% / 2)',
    flexGrow: 0,
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  '@media screen and (min-width: 75em)': {
    flexBasis: 'calc(100% / 3)',
  },
})
