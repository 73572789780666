/* eslint-disable import/prefer-default-export */
import { styled } from 'styletron-react'

/**
 * Base Box Component
 *
 * @returns
 */
const ContentRoot = styled('main', ({ $theme, $noHeader }) => ({
  ...$theme.typography.base,
  position: 'relative',
  flex: 1,
  //paddingTop: $noHeader ? '0px' : `${Number($theme.mixins.toolbar.minHeight.slice(0, -2)) * Math.min(profile.owner.length, 2)}px`,
  paddingTop: $noHeader ? '0px' : $theme.mixins.toolbar.minHeight,
  paddingBottom: `${$theme.spacing(4)}px`,
  backgroundColor: $theme.palette.background,
  color: $theme.palette.text,
}))

export default ContentRoot
