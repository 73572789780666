import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { LinkButton } from '../../components/ui/button'
import Icon from '../../components/ui/icon'
import Notification from '../../components/ui/notification'
import Typography from '../../components/ui/typography'

import LoginForm from '../../components/login-form'
import OwnersLogo from '../../components/owners-logo'
import PasswordResetForm from '../../components/password-reset-form'

import AuthContext from '../../context/auth'
import Theme from '../../context/theme'
import ContentRoot, { ContentContainer } from './auth.styled'
import { login_page as parkMessages } from './park-messages.json'

import SideMenu from '../../layout/side-menu/index'
import { CORPORATE, OWNERSHIP, CORPORATE_DARK } from 'src/constants'

const styles = {
  content: {
    width: '100%',
    maxWidth: '320px',
  },
}

/**
 * Login Page
 *
 * @returns
 */
const Login = ({ location }) => {

  console.log(location);

  const { from } = location.state || {}
  const auth = useContext(AuthContext)
  const { theme } = useContext(Theme)

  const [cognitoUser, setCognitoUser] = useState(null)
  const [isCompleted, setIsCompleted] = useState(false)
  const [notifications, setNotifications] = useState([])

  const urlSearchParams = new URLSearchParams(location.search);
  const source = urlSearchParams.has("source") ? urlSearchParams.get('source') : null;
  const parkMessage = source ? parkMessages[source] : null;

  const handleInvalidForm = (errorMessage) => setNotifications([{
      context: 'error',
      title: 'Error',
      messages: [errorMessage],
    }])

  const handleLogin = async (emailAddress, password) => {
    const status = await auth.signIn(emailAddress, password)
    setIsCompleted(true)

    if (!status.ok) {
      setNotifications([{
        context: 'error',
        pale: true,
        messages: [status.message].concat(
          status.attemptsExceeded
            ? ['You account is now locked, please try again in 5 minutes.']
            : [],
        ),
      }])
    } else if (status.newPasswordRequired) {
      setNotifications([])
      setCognitoUser(status.data)
    }
  }

  const handleChangePassword = async (newPassword) => {
    const status = await auth.completeNewPassword(cognitoUser, newPassword)
    setIsCompleted(true)
    if (!status.ok) handleInvalidForm(status.message)
  }

  if (!auth.isGuest && !auth.forcePasswordChange)
    return <Redirect to={from && from.pathname !== '/logout' ? from : '/'} />

  let NotificationCollection = null;
  if (notifications.length > 0) {
    NotificationCollection = () => (
      <>
        {notifications.map(notification => (
          <Notification
            key={notification}
            {...notification}
            style={{ marginBottom: theme.spacing(2) }}
          />
        ))}
      </>
    )
  }

  return (
    <ContentRoot>
      <ContentContainer $color={CORPORATE} $theme={theme}>
        <OwnersLogo width="200" style={{ marginBottom: theme.spacing(2) }} />

      <SideMenu />

        {!auth.forcePasswordChange && parkMessage ? (
          <Notification
            context="info"
            messages={[parkMessage]}
            style={{ ...styles.content, marginBottom: theme.spacing(2) }}
          />
        ) : null}

        {auth.forcePasswordChange ? (
          <PasswordResetForm
            isCompleted={isCompleted}
            notification={NotificationCollection ? <NotificationCollection /> : null}
            onSubmit={handleChangePassword}
            onInvalid={() => handleInvalidForm('Your new password and confirmation do not match')}
          />
        ) : (
          <LoginForm
            theme={theme}
            isCompleted={isCompleted}
            notification={NotificationCollection ? <NotificationCollection /> : null}
            onSubmit={handleLogin}
            style={styles.content}
          />
        )}
      </ContentContainer>

      {!auth.forcePasswordChange ? (
        <ContentContainer $theme={theme}>
          <div style={styles.content}>
          <Typography as="h2" color={theme.isPl2k ?  CORPORATE_DARK : OWNERSHIP}>
            {theme.isPl2k ? 'New to the Owners Lounge?' : 'New to the Owners Area?'}
            </Typography>

            <LinkButton
              to="/register"
              color={theme.isPl2k ? CORPORATE_DARK : OWNERSHIP}
              variant="outlined"
              fullWidth
              align="center"
              endIcon={<Icon name="chevron-right" />}
            >
              Register
            </LinkButton>
          </div>
        </ContentContainer>
      ) : null}
    </ContentRoot>
  )
}

Login.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
}

export default Login
