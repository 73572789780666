import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../components/ui/button'
import { BurgerMenuIcon } from '../../components/ui/icon'
import VisibleOn, { HiddenOn } from '../../components/hoc/visible-on'

import styles from './styles'

const menuButtonProptypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
}

/**
 * My Account Button
 *
 */
const MenuButton = ({ color, active, onClick, ...otherProps }) => {
  const { burgerMenuIconStyles } = styles()
  return (
    <>
      <Button
        endIcon={
          <HiddenOn
            component={
              <BurgerMenuIcon
                color={color}
                active={active}
                style={burgerMenuIconStyles}
              />
            }
            breakpoints={['xs', 'sm']}
          />
        }
        rounded={false}
        size="small"
        color={color}
        title={`${active ? 'close' : 'open'} menu`}
        aria-label={`${active ? 'close' : 'open'} menu`}
        onClick={onClick}
        {...otherProps}
      >
        <HiddenOn component={<>Menu</>} breakpoints={['xs', 'sm']} />
        <VisibleOn
          component={<BurgerMenuIcon color={color} active={active} />}
          breakpoints={['xs', 'sm']}
        />
      </Button>
    </>
  )
}

MenuButton.propTypes = menuButtonProptypes
MenuButton.defaultProps = {
  active: false,
  color: 'default',
  onClick: undefined,
}

export default MenuButton
