import React from 'react'
import PropTypes from 'prop-types'
import { styled, useStyletron } from '@parkholidays/legacy_components'
import Text from '@parkholidays/legacy_components/dist/text/Text'
import Image from '@parkholidays/legacy_components/dist/image/Image'
import { applyColor } from 'src/constants'
import Theme from '../../../../context/theme'

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  media: PropTypes.shape({
    path: PropTypes.string,
    alternateText: PropTypes.string,
  }),
}

const ResourceContent = ({ title, description, className, media }) => {
  const { theme:{isPl2k} } = React.useContext(Theme)
  const [css] = useStyletron()
  const MEDIA_PREFIX = '//assets.parkholidays.com/300x300/assets/'

  return (
    <article className={className}>
      {media && media.path && (
        <div
          className={css({
            flex: '1 0 100px',
            marginRight: '1rem',
          })}
        >
          {media && (
            <Image
              {...{
                src: `${MEDIA_PREFIX}${media.path}`,
                alt: media.alternateText,
              }}
            />
          )}
        </div>
      )}

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          flexShrink: '1',
          flexBasis: 'auto',
        })}
      >
        <Text
          color={applyColor(isPl2k)}
          weight="bolder"
          allCaps
          $style={{ marginBottom: '0.5rem' }}
        >
          {title}
        </Text>
        <Text>{description}</Text>
      </div>
    </article>
  )
}

ResourceContent.propTypes = propTypes

export default styled(ResourceContent, {
  boxSizing: 'border-box',
  display: 'flex',
  flexGrow: 1,
  paddingTop: '1rem',
  paddingRight: '1rem',
  paddingBottom: '0',
  paddingLeft: '1rem',
})
