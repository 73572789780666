import React from 'react';
import { Helmet } from 'react-helmet-async';

const ParkLeisureHead = () => {
  const hostIsParkLeisureProduction = window.location.hostname === 'owners.parkleisure.co.uk';
  console.log({ hostIsParkLeisureProduction });
  return (
    <Helmet>
      <title>Owners Lounge</title>
      <link rel="canonical" href="https://owners.parkleisure.co.uk/" />
      <link rel="icon" href="/favicon-pl.ico" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <link rel="" href="" />
      {/* Onetrust cookies consent script*/}
      {hostIsParkLeisureProduction ? (
        <>
          <script
            type="text/javascript"
            src="https://cdn.cookielaw.org/consent/018e5393-fc30-73f4-a78e-ed4782b868bf/OtAutoBlock.js"
          ></script>
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script="018e5393-fc30-73f4-a78e-ed4782b868bf"
          ></script>
        </>
      ) : (
        <>
          <script
            type="text/javascript"
            src="https://cdn.cookielaw.org/consent/018e5393-fc30-73f4-a78e-ed4782b868bf-test/OtAutoBlock.js"
          ></script>
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script="018e5393-fc30-73f4-a78e-ed4782b868bf-test"
          ></script>
        </>
      )}
      <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
    </Helmet>
  );
};

export default ParkLeisureHead;
