import React from 'react'
import { useStyletron } from 'styletron-react'

import Image from '../../../ui/image'
import Typography from '../../../ui/typography'
import { ASSETS_PREFIX } from '../../../../config'

import { DetailedParkWrapper, DetailedParkImage } from './detailed-park.styled'

const DetailedPark = ({ park, ...otherProps }) => {
  const [css] = useStyletron()
  const { logo, name } = park
  const { path, alternateText } = logo ?? {}

  return (
    <DetailedParkWrapper {...otherProps}>
      <DetailedParkImage>
        <Image src={`${ASSETS_PREFIX}assets/${path}`} alt={alternateText} />
      </DetailedParkImage>
      <Typography
        as="p"
        className={css({ marginBottom: '0' })}
      >{`${name} Holiday Park`}</Typography>
    </DetailedParkWrapper>
  )
}
export default DetailedPark
