/*
  DO NOT MODIFY THIS FILE
*/
import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '../styles'

const propTypes = {
  block: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string.isRequired,
}

const defaultProps = {
  block: false,
  height: '1ex',
  width: '60%',
}

const Shimmer = ({ block, height, width, className }) =>
  React.createElement('span', {
    'aria-busy': 'true',
    width: typeof width === 'number' ? `${width}px` : width,
    height: typeof height === 'number' ? `${height}px` : height,
    className,
  })

export default styled(Shimmer, ({ block, width, height }) => ({
  animationDirection: 'alternate',
  animationDuration: '1s',
  animationFillMode: 'forwards',
  animationIterationCount: 'infinite',
  animationName: {
    from: {
      opacity: 0.1,
    },
    to: {
      opacity: 0.3,
    },
  },
  animationTimingFunction: 'ease-in-out',
  backgroundColor: 'currentColor',
  display: block ? 'block' : 'inline-block',
  position: 'relative',
  '@media (prefers-reduced-motion)': {
    animation: 'none',
    opacity: 0.1,
  },
}))
