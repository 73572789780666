import React from 'react'

import Icon from '../../../ui/icon'
import { ParkWrapper } from './park.styled'

const Park = ({ park }) => {
  const { name } = park
  return (
    <ParkWrapper>
      {`${name} Holiday Park`}
      <Icon name="chevron-down" color="white" />
    </ParkWrapper>
  )
}
export default Park
