import { styled } from 'styletron-react'

export const ContentRoot = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundColor: '#08375F', //'#0c5595',
})
