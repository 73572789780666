import React from 'react'

import Typography from '../../../../components/ui/typography'
import Theme from '../../../../context/theme'
import Anchor from '../../../../components/ui/anchor'

const FaqContactUs = () => {
  const { theme } = React.useContext(Theme)
  return (
    <div
      style={{
        marginTop: `${theme.spacing(1)}px`,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
      }}
    >
      <Typography color="ownership">
        Questions organised by category. If you can't find the answer you need,{' '}
        <Anchor aria-label='contact-us' href="/contact-us"> contact us </Anchor>
      </Typography>
    </div>
  )
}
export default FaqContactUs
