import React from 'react'
import PropTypes from 'prop-types'

import { FAQContentWrapper } from './styled-components'

const propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool,
}

const FAQContent = ({ children, open }) => (
  <FAQContentWrapper $style={{ display: open ? 'block' : 'none' }}>
    {children}
  </FAQContentWrapper>
)

FAQContent.propTypes = propTypes
FAQContent.defaultProps = { open: false }

export default FAQContent
