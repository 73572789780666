import React, { useContext } from 'react'
import { useStyletron } from '@parkholidays/legacy_components'

import Text from '@parkholidays/legacy_components/dist/text/Text'
import Image from '@parkholidays/legacy_components/dist/image/Image'

import BBCode from '../../../components/bbcode'
import { applyColor } from 'src/constants'
import Theme from '../../../context/theme'
import { useContentStackImageSrc } from '../../../hooks/useContentStackImageSrc';

const Message = ({ managerMessage }) => {
  const [css, theme] = useStyletron()
  const {theme:{isPl2k}} = useContext(Theme)
  const color = applyColor(isPl2k)
  const { message, park, contact } = managerMessage
  const { media, firstname, surname, jobRole } = contact || {}
  const contentStackImageSrc = useContentStackImageSrc(media);

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '1rem',
        paddingRight: '1rem',
        paddingBottom: '1rem',
        paddingLeft: '1rem',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: theme.lighting.shadow500,
        '@media all and (min-width: 62em)': {
          flexDirection: 'row',
        },
      })}
    >
      <div
        className={css({
          marginBottom: '1rem',
          '@media all and (min-width: 62em)': {
            flexShrink: 0,
            flexGrow: 1,
            flexBasis: '320px',
            marginBottom: '0',
          },
        })}
      >
        <div className={css({ display: 'flex' })}>
          {media && media.path ? (
            <div className={css({ marginRight: '1rem' })}>
              <Image
                src={contentStackImageSrc ?? `https://assets.parkholidays.com/100x100/assets/${media.path}`}
                alt={`${firstname} ${surname} Staff Photo`}
                height="100px"
                width="100px"
                noLoading
              />
            </div>
          ) : null}

          <div>
            <Text color={color} weight="bolder">
              Greetings from {park ? park.name : ''}
            </Text>
            <Text>
              {firstname} {surname}
            </Text>
            {jobRole && jobRole.jobTitle && (
              <Text color={color}>{jobRole.jobTitle}</Text>
            )}
          </div>
        </div>
      </div>

      <div>
        <BBCode>{message}</BBCode>
        <Text weight="bolder">Best Wishes,</Text>
        <Text weight="bolder">
          {firstname} {surname}
        </Text>
        <Text weight="bolder">
          {park ? park.name : ''}{' '}
          {jobRole && jobRole.jobTitle ? jobRole.jobTitle : ''}
        </Text>
      </div>
    </div>
  )
}

export default Message
