import MarkdownComponent from 'markdown-to-jsx'
import PropTypes from 'prop-types'
import React from 'react'

import Anchor from '../../components/ui/anchor'
import Container from '../../components/ui/container'
import Icon from '../../components/ui/icon'
import Typography from '../../components/ui/typography'

import Theme from '../../context/theme'

const Markdown = ({ pageTitle, markdownSlug, location, history }) => {
  const { theme } = React.useContext(Theme)
  const [pageContent, setPageContent] = React.useState('')

  const internalReferrer =
    document.referrer.length >= window.location.origin.length &&
    document.referrer.substring(0, window.location.origin.length) ===
      window.location.origin

  if (theme.isPl2k) markdownSlug += "-pl"; // Add pl suffix to markdowns on Park Leisure URL

  React.useEffect(() => {
    let mounted = true
    // eslint-disable-next-line no-undef
    fetch(
      `https://raw.githubusercontent.com/park-holidays-uk/${encodeURI(
        markdownSlug,
      )}.md`,
      { method: 'get' },
    )
      .then(response => response.text())
      .then(content => (mounted ? setPageContent(content) : false))
      .catch(exception => console.error(exception))

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <Container $style={{ marginTop: `${theme.spacing(2)}px` }}>
      {internalReferrer ? (
        <>
          <Anchor
            aria-label='Go back'
            native
            onClick={() => history.goBack()}
            startIcon={<Icon name="navigate_before" />}
            style={{ cursor: 'pointer' }}
          >
            Go Back
          </Anchor>
          <br />
          <br />
        </>
      ) : null}

      <Typography as="h1">{pageTitle}</Typography>
      <MarkdownComponent>{pageContent}</MarkdownComponent>
    </Container>
  )
}

Markdown.propTypes = {
  // returnTo: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  markdownSlug: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

// Markdown.defaultProps = { returnTo: undefined };

export default Markdown
