/*
  DO NOT MODIFY THIS FILE
*/
import React from 'react'
import PropTypes from 'prop-types'
import { LoadingContext } from '../with-loading/LoadingContext'

const propTypes = {
  noLoading: PropTypes.bool.isRequired,
}

const defaultProps = {
  noLoading: false,
}

const WithLoading = WrappedComponent => ({ noLoading, ...props }) => {
  const { isLoading } = React.useContext(LoadingContext)
  return <WrappedComponent isLoading={!noLoading && isLoading} {...props} />
}

WithLoading.propTypes = propTypes
WithLoading.defaultProps = defaultProps

export default WithLoading
