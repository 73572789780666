import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron, ThemeContext } from '@parkholidays/legacy_components'
import OwnershipButton from '@parkholidays/legacy_components/dist/button/OwnershipButton'
import SecondaryButton from '@parkholidays/legacy_components/dist/button/SecondaryButton'
import Image from '@parkholidays/legacy_components/dist/image/Image'
import Text from '@parkholidays/legacy_components/dist/text/Text'
import { formatDate } from '../../../../helpers/formatDate'
import { PL2KBLACK } from 'src/constants'

const dateFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
}

const timeFormatOptions = {
  hour: 'numeric',
  hour12: true,
  minute: 'numeric',
}

const propTypes = {
  isOwnersExclusive: PropTypes.bool.isRequired,
  isPinned: PropTypes.bool,
  isCancelled: PropTypes.bool,
  title: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  slug: PropTypes.string,
  type: PropTypes.string,
  category: PropTypes.string,
  defaultCategoryMedia: PropTypes.shape({
    path: PropTypes.string,
    alternateText: PropTypes.string,
  }),
  media: PropTypes.shape({
    path: PropTypes.string,
    alternateText: PropTypes.string,
  }),
  contextTheme: PropTypes.shape({
    isPl2k: PropTypes.boolean, 
    colors: PropTypes.object,
  }),
}

const EventCard = ({
  isOwnersExclusive,
  isPinned,
  isCancelled,
  title,
  startDate,
  endDate,
  slug,
  type,
  category,
  defaultCategoryMedia,
  media,
  contextTheme,
}) => {
  const [css] = useStyletron()
  const theme = React.useContext(ThemeContext)

  // Use media from event if we have one
  // otherwise default to the category generic
  // image
  const MEDIA_PREFIX = '//assets.parkholidays.com/433x240/assets/'
  const isContentStackImage = media && media.path && media.path.includes('contentstack')
  const imageProps = {
    ...(media && media.path && !isContentStackImage ? {
          src: `${MEDIA_PREFIX}${media.path}`,
          alt: media?.alternateText,
        }
      : {
          src: `${MEDIA_PREFIX}${defaultCategoryMedia?.path}`,
          alt: defaultCategoryMedia?.alternateText,
        }),
    ...(isContentStackImage ? { src: media.path, alt: media?.alternateText } : {}),
    aspectRatio: '0.4',
    objectFit: 'cover',
    objectPosition: '50% 50%',
  }
  const { isPl2k } = contextTheme || {}
  const isOwnersExclusiveColor = isOwnersExclusive ? 'white' : 'ownership'
  const textColor = isPl2k ? PL2KBLACK : isOwnersExclusiveColor

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderRadius: '4px',
        ...(isCancelled && { opacity: 0.7 }),
        boxShadow: theme.lighting.shadow500,
        marginBottom: '16px',
      })}
    >
      <div
        className={css({
          position: 'relative',
          flex: '1 1 50%',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          overflow: 'hidden',
        })}
      >
        {isCancelled && (
          <div
            className={css({
              position: 'absolute',
              width: '200px',
              top: '15px',
              left: '-50px',
              textAlign: 'center',
              backgroundColor: theme.palette.common.white,
              transform: 'rotate(-35deg)',
              WebkitTransform: 'rotate(-35deg)',
              letterSpacing: '1px',
              lineHeight: '40px',
              zIndex: 1,
            })}
          >
            <Text>Cancelled</Text>
          </div>
        )}
        <Image {...imageProps} />
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 50%',
          backgroundColor: isOwnersExclusive
            ? theme.palette.ownership
            : theme.palette.common.white,
          padding: '1rem',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
        })}
      >
        <div>
          <Text
            weight="bolder"
            $style={{ marginBottom: '0.25rem' }}
            color={textColor}
          >
            {title}
          </Text>
        </div>
        <div className={css({ flexGrow: 1, marginBottom: '0.5rem' })}>
          <Text weight="bolder" color={textColor}>{`${formatDate(
            startDate,
            dateFormatOptions,
          )} - ${formatDate(endDate, dateFormatOptions)}`}</Text>
          <Text $style={{ marginBottom: '0.5rem' }} color={textColor}>
            {`${formatDate(startDate, timeFormatOptions)} - ${formatDate(
              endDate,
              timeFormatOptions,
            )}`}
          </Text>
        </div>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          })}
        >
          <Text size="small" color={textColor}>
            {type}
          </Text>
          {isOwnersExclusive ? (
            <SecondaryButton ariaLabel='details' href={`/events/${slug}`}>Details</SecondaryButton>
          ) : (
            <OwnershipButton ariaLabel='details' href={`/events/${slug}`}>Details</OwnershipButton>
          )}
        </div>
      </div>
    </div>
  )
}

EventCard.propTypes = propTypes

export default EventCard
