import React from 'react'

import { EventGrid, EventGridItem } from '../EventGrid/EventGrid'
import EventCard from '../EventCard/EventCard'

const EventsContent = ({ events, contextTheme }) => {
  return (
    <EventGrid>
      {events.map(
        ({
          node: {
            id,
            pinned,
            type,
            cancelled,
            title,
            startDate,
            endDate,
            slug,
            category,
            media,
          },
        }) => (
          <EventGridItem key={id}>
            <EventCard
              isOwnersExclusive={type && type.name === 'Owners Only'}
              isPinned={pinned}
              isCancelled={cancelled}
              title={title}
              startDate={startDate}
              endDate={endDate}
              slug={slug}
              type={type.name}
              category={category.name}
              defaultCategoryMedia={category.media}
              media={media}
              contextTheme={contextTheme}
            />
          </EventGridItem>
        ),
      )}
    </EventGrid>
  )
}

export default EventsContent
