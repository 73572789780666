import parse from 'date-fns/parse'
import { format } from 'date-fns'

const formatTimeRange = (startDate, endDate) => {
  const options = {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  }
  const formattedStartDate = new Date(startDate).toLocaleString(
    'en-gb',
    options,
  )
  const formattedEndDate = new Date(endDate).toLocaleString('en-us', options)
  return `${formattedStartDate} - ${formattedEndDate}`
}

const formatDateRange = (startDate, endDate) => {
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  }
  const formattedStartDate = new Date(startDate).toLocaleDateString(
    'en-gb',
    options,
  )
  const formattedEndDate = new Date(endDate).toLocaleDateString(
    'en-gb',
    options,
  )
  return formattedStartDate !== formattedEndDate
    ? `${formattedStartDate} - ${formattedEndDate}`
    : `${formattedStartDate}`
}

const formatDate = (date, options) => {
  // return new Date(date).toLocaleString('en-gb', options)
  if (Object.prototype.hasOwnProperty.call(options, 'hour')) {
    return format(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'h:mm aaa')
  }

  return format(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'EEE do MMM')
}

const JSDateToGraphQL = date => {
  return date
    .toISOString()
    .substring(0, 19)
    .replace('T', ' ')
}

export { formatDate, formatTimeRange, formatDateRange, JSDateToGraphQL }
