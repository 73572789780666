import { MY_BRAND_URLS } from './config'
export const OWNERS_PARK_LEISURE = 'Owners Park Leisure'
export const OWNERS_PARK_HOLIDAYS = 'Owners Park Holidays'
export const OWNERSHIP = 'ownership'
export const CORPORATE = 'corporate'
export const CORPORATE_DARK = 'corporateDark'
export const PL2KBLUE = 'pl2kBlue'
export const PL2KBLACK = 'pl2kBlack'

export const applyColor = (pl2k) => (pl2k ? PL2KBLUE : OWNERSHIP)
export const myUrl = (pl2k) =>
  pl2k ? MY_BRAND_URLS.myParkleisureURL : MY_BRAND_URLS.myParkholidaysURL

// Assets
export const OWNERS_LOGO_SMALL =
  '//parkholidays.s3.amazonaws.com/static_assets/svg_logo/PL-ownerslounge-logo-small.svg'

export const PL_LOGO =
  '//parkholidays.s3.amazonaws.com/static_assets/pl-logo-transparent.png'
  
export const PH_LOGO =
  '//my-park-holidays.s3.eu-west-2.amazonaws.com/static/media/ownersarea-logo-small-ph.svg'
