import React from 'react'
import PropTypes from 'prop-types'
// import { useQuery } from '@apollo/react-hooks';
// import { JSDateToGraphQL } from '../../../helpers/formatDate';
import Divider from '../../../components/ui/divider'
import Icon from '../../../components/ui/icon'

import Carousel from '../../../components/ui/carousel'
import Typography from '../../../components/ui/typography'

import Theme from '../../../context/theme'
import Notice from './notice'

const Notices = ({ data }) => {
  const { theme } = React.useContext(Theme)

  // let noticeCards

  // if (data) {
  //   if (data.length) {
  //     noticeCards = data.map(notice => {
  //       return (
  //         <Notice
  //           key={notice.id}
  //           title={notice.title}
  //           description={notice.description}
  //         />
  //       )
  //     })
  //   } else {
  //     return null
  //   }
  // }

  return (
    <>
      <Divider />
      <Typography
        as="h1"
        weight="bold"
        $style={{ marginBottom: data.length > 1 ? '-35px' : '1rem' }}
        beforeEnhancer={
          <Icon
            name="oa-notices"
            size={24}
            style={{ marginRight: theme.spacing(1) }}
          />
        }
      >
        Notices
      </Typography>

      {/* <Carousel>{noticeCards}</Carousel> */}
      <Carousel>
        {data.map(({ id, title, description }) => (
          <Notice key={id} title={title} description={description} />
        ))}
      </Carousel>
    </>
  )
}

Notices.propTypes = {
  data: PropTypes.array.isRequired,
}

export default Notices
