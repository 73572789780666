import { styled } from 'styletron-react'

export const NEXT = 'NEXT'
export const PREV = 'PREV'

const CarouselRoot = styled('div', {
  width: '100%',
  overflow: 'hidden',
})

export const Wrapper = styled('div', ({ $isMobile, $theme }) => ({
  position: 'relative',
  width: '100%',
  boxSizing: 'border-box',
  ...(!$isMobile ? { padding: `0 ${$theme.spacing(4.5)}px` } : {}),
}))

export const Dot = styled('div', ({ $active, $theme }) => ({
  borderRadius: '50%',
  width: `${$theme.spacing(2)}px`,
  height: `${$theme.spacing(2)}px`,
  border: `3px solid ${$theme.palette.corporate}`,
  backgroundColor: $active ? $theme.palette.corporate : 'transparent',
  boxSizing: 'border-box',
}))

export const Item = styled('div', ({ $order, $isMobile }) => ({
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: $isMobile ? '100%' : '50%',
  padding: '0 8px',
  order: `${$order}`,
  boxSizing: 'border-box',
}))

export const Track = styled(
  'div',
  ({ $count, $dir, $interacted, $sliding, $isMobile }) => {
    const itemWidth = `${$count > 1 ? ($isMobile ? 100 : 50) : 100}%`
    const transition = $sliding ? 'none' : 'transform 1s ease'
    const translate = () => {
      if ($interacted && !$sliding) return `translateX(-${itemWidth})`
      if ($dir === PREV) return `translateX(calc(2 * (-${itemWidth})))`
      return 'translateX(0%)'
    }

    return {
      display: 'flex',
      transition,
      transform: translate(),
    }
  },
)

export default CarouselRoot
