import { useApolloClient } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import AuthContext from '../auth'
import actions from './actions'
import GET_PARKS from './graphql'

const initialState = {
  isLoading: false,
  allParks: [],
  park: null,
  error: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_CURRENT_PARK:
      return {
        ...state,
        park: action.payload,
      }
    case actions.GET_PARKS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case actions.SET_CURRENT_PARKS:
    case actions.GET_PARKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allParks: action.payload || [],
      }
    case actions.GET_PARKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case actions.CLEAR_STATE:
      return { ...initialState }
    default:
      return state
  }
}

const ParkContext = React.createContext(null)

export const ParkProvider = ({ children }) => {
  const { user, isGuest } = React.useContext(AuthContext)
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const client = useApolloClient()

  // METHODS
  const setPark = parkId =>
    dispatch({ type: actions.SET_CURRENT_PARK, payload: state.allParks.find(p => p.id === parkId) })

  const clearPark = () => setPark(null);
  const getAllParks = async () => {
    try {
      const parkIds = user.parkIds || []
      if (parkIds.length > 0) {
        dispatch({ type: actions.GET_PARKS_START })
        const { data } = await client.query({
          query: GET_PARKS,
          variables: {
            filters: [{
              "column": "active",
              "operator": ">=",
              "value": "0"
            },
            {
              "column": "id",
              "operator": "IN",
              "value": parkIds
            }]
          },
        })

        dispatch({ type: actions.GET_PARKS_SUCCESS, payload: data.allParks })
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: actions.GET_PARKS_FAILURE, payload: error })
    }
  }

  /** USE EFFECTS */
  useEffect(() => {
    if (state.park) localStorage.setItem('parkId', state.park.id)
    else localStorage.removeItem('parkId');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.park])

  useEffect(() => {
    const { allParks, park } = state;
    const current = park ? allParks.find(p => p.id === park.id) : null

    if (allParks.length > 0) {
      if (!current) {
        let parkId = localStorage.getItem('parkId')
        const newParkId = (allParks.find(park => park.id === parkId) || allParks[0]).id
        setPark(newParkId)
      }
    } else if (current)
      clearPark()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.allParks])

  useEffect(() => {
    if (!isGuest) getAllParks()
    else dispatch({ type: actions.CLEAR_STATE })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isGuest])

  return (
    <ParkContext.Provider value={{ ...state, setPark, clearPark }}>
      {children}
    </ParkContext.Provider>
  )
}

ParkProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export default ParkContext
