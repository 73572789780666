import IconOwnersArea from '@parkholidays/legacy_components/dist/icon/IconOwnersArea'
import PropTypes from 'prop-types'
import React from 'react'

import Anchor from '../../components/ui/anchor'
import Appbar from '../../components/ui/appbar'
import Navbar from '../../components/ui/navbar'
import Spacer from '../../components/ui/spacer'
import Theme from '../../context/theme'

import ContactUsLink from './contact-us-link'
import MenuButton from './menu-button'
import MyAccountButton from './my-account-button'
import { CORPORATE, OWNERS_LOGO_SMALL } from 'src/constants'

import styles from './styles'

const appHeaderProptypes = {
  sideMenuOpen: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  onMenuButtonClick: PropTypes.func,
}

/**
 * Application Header Bar Component
 *
 * @returns
 */
const AppHeader = ({ sideMenuOpen, isLoggedIn, onMenuButtonClick }) => {
  const { appHeaderStyles } = styles()
  const { theme: {isPl2k} } = React.useContext(Theme)

  return (
    <Appbar color={CORPORATE} $style={appHeaderStyles}>
      <Anchor aria-label='go to home page' to="/">
      {isPl2k ?
          <img src={OWNERS_LOGO_SMALL} alt="Owners Lounge" height="36px"/>
        :
          <IconOwnersArea height="36" />
        }
      </Anchor>
      <Spacer />
      {isLoggedIn ? (
        <Navbar>
          <MyAccountButton color={CORPORATE} />
          <ContactUsLink color={CORPORATE} />
          <MenuButton
            color={CORPORATE}
            active={sideMenuOpen}
            onClick={onMenuButtonClick}
          />
        </Navbar>
      ) : null}
    </Appbar>
  )
}

AppHeader.propTypes = appHeaderProptypes
AppHeader.defaultProps = {
  sideMenuOpen: true,
  isLoggedIn: false,
  onMenuButtonClick: undefined,
}

export default React.memo(AppHeader)
