import { gql } from 'apollo-boost';

const GET_FAQS = gql`
  query allFaqs($criteria: FaqCriteria) {
    allFaqs(criteria: $criteria) {
      id
      question
      answer
      sectorId
      category {
        id
        title
      }
    }
    allFaqCategories {
      id
      title
    }
  }
`;

export default GET_FAQS;
