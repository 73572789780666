import { gql } from 'apollo-boost'

const GET_MANAGERS_MESSAGE = gql`
  query allParkMessages($filters: [Filter]) {
    allParkMessages(filters: $filters) {
      id
      message
      publishAt
      expiresAt
      createdAt
      park {
        name
      }
      type {
        id
        name
      }
      contact {
        salutation
        firstname
        surname
        jobRole {
          jobTitle
        }
        media {
          id
          path
          alternateText
        }
      }
    }
  }
`

export default GET_MANAGERS_MESSAGE
