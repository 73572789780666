import React from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { format, parse, startOfDay, subYears } from 'date-fns'

import Typography from '../../components/ui/typography'
import Icon from '../../components/ui/icon'
import Container from '../../components/ui/container'
import ParkSelectToolbar from '../../components/parkSelectToolbar'
import Loading from '../../components/ui/loading'
import Message from './components/Message'
import PaginationBar from '../../components/pagination/PaginationBar'

import ParkContext from '../../context/park'
import Theme from '../../context/theme'

import GET_MANAGERS_MESSAGE from './graphql'
import { MANAGERS_MESSAGE_TYPE_ID } from '../../config'

const ManagersMessage = () => {
  const { park } = React.useContext(ParkContext)
  const { theme } = React.useContext(Theme)
  const client = useApolloClient()

  const [state, setState] = React.useState({
    loading: false,
    error: undefined,
    messages: [],
    currentMessageIndex: 0,
  })

  React.useEffect(() => {
    let mounted = true

    const getMessages = async () => {
      if (mounted)
        setState(prevState => ({
          ...prevState,
          loading: true,
        }))

      try {
        const { data } = await client.query({
          query: GET_MANAGERS_MESSAGE,
          variables: {
            filters: [
              {
                column: 'park_id',
                operator: '=',
                value: park.id,
              },
              {
                column: 'park_message_type_id',
                operator: '=',
                value: MANAGERS_MESSAGE_TYPE_ID,
              },
              {
                column: 'publish_at',
                operator: '>=',
                value: format(
                  startOfDay(subYears(new Date(), 1)),
                  'yyyy-MM-dd HH:mm:ss',
                ),
              },
              {
                column: 'publish_at',
                operator: '<=',
                value: format(startOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
              },
            ],
          },
        })

        if (mounted)
          setState(prevState => ({
            ...prevState,
            loading: false,
            messages: data.allParkMessages.sort((a, b) =>
              a.publishAt < b.publishAt
                ? 1
                : a.publishAt > b.publishAt
                ? -1
                : 0,
            ),
          }))
      } catch (error) {
        if (mounted)
          setState(prevState => ({
            ...prevState,
            loading: false,
            error,
          }))
      }
    }

    if (mounted) getMessages()
    return () => {
      mounted = false
    }
  }, [client, park.id])

  const handlePaginationChange = adjustment => {
    setState(prevState => ({
      ...prevState,
      currentMessageIndex: currentMessageIndex + adjustment,
    }))
  }

  const { loading, error, messages, currentMessageIndex } = state
  const managerMessage = messages[currentMessageIndex]

  if (error) {
    return (
      <Container>
        <div>
          Whoops! Unfortunately we encountered a problem whilst trying to load
          the messages. Please try again later.
        </div>
      </Container>
    )
  }

  return (
    <>
      <ParkSelectToolbar />
      <Container $style={{ marginTop: `${theme.spacing(2)}px` }}>
        <Typography
          as="h1"
          weight="bold"
          beforeEnhancer={
            <Icon
              name="oa-managersmessage"
              size={24}
              style={{ marginRight: theme.spacing(1) }}
            />
          }
        >
          Managers Message
        </Typography>
      </Container>
      <Container>
        {loading ? <Loading /> : null}

        {!loading && messages.length ? (
          <React.Fragment>
            <PaginationBar
              pageLabel={format(
                parse(
                  managerMessage.publishAt,
                  'yyyy-MM-dd HH:mm:ss',
                  new Date(),
                ),
                'EEE do MMM yyyy',
              )}
              previousDisabled={currentMessageIndex >= messages.length - 1}
              nextDisabled={currentMessageIndex === 0}
              onHandlePreviousClick={() => handlePaginationChange(1)}
              onHandleNextClick={() => handlePaginationChange(-1)}
              style={{ marginBottom: 16 }}
            />
            <Message managerMessage={managerMessage} />
          </React.Fragment>
        ) : null}
      </Container>
    </>
  )
}

export default ManagersMessage
