import PropTypes from 'prop-types'
import React from 'react'

import VisibleOn, { HiddenOn } from '../../components/hoc/visible-on'
import { LinkButton } from '../../components/ui/button'
import Icon from '../../components/ui/icon'

import Theme from '../../context/theme'
import { myUrl } from 'src/constants'

const myAccountButtonProptypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
}

/**
 * My Account Button
 *
 */
const MyAccountButton = ({ color, onClick, ...otherProps }) => {
  const {
    theme: { isPl2k },
  } = React.useContext(Theme)

  return (
  <>
    <HiddenOn
      component={
        <LinkButton
          native
          target="_blank"
          to={myUrl(isPl2k)}
          endIcon={<Icon name="chevron-right" size={16} color="white" />}
          rounded={false}
          size="small"
          color={color}
          aria-label="Go to My Account Area"
          onClick={onClick}
        >
          My Account
        </LinkButton>
      }
      breakpoints={['xs', 'sm']}
      {...otherProps}
    />

    <VisibleOn
      component={
        <LinkButton
          native
          target="_blank"
          to={myUrl(isPl2k)}
          size="small"
          rounded={false}
          color={color}
          aria-label="Login to My Account Area"
          onClick={onClick}
          endIcon={<Icon name="oa-myaccount" size={24} color="white" />}
        ></LinkButton>
      }
      breakpoints={['xs', 'sm']}
      {...otherProps}
    />
  </>
)}

MyAccountButton.propTypes = myAccountButtonProptypes
MyAccountButton.defaultProps = {
  color: undefined,
  onClick: undefined,
}

export default MyAccountButton
