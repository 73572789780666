import { gql } from 'apollo-boost'

const GET_DASHBOARD_DATA = gql`
  query dashboard(
    $websiteAnnouncementFilters: [Filter]
    $websiteAnnouncementCriteria: WebsiteAnnouncementCriteria
    $eventsFilters: [Filter]
    $eventCriteria: EventCriteria
    $parkMessageFilters: [Filter]
    $parkNoticeFilters: [Filter]
    $parkNoticeCriteria: ParkNoticeCriteria
    $specialOfferFilters: [Filter]
    $specialOfferCriteria: SpecialOfferCriteria
  ) {
    allWebsiteAnnouncements(
      filters: $websiteAnnouncementFilters
      criteria: $websiteAnnouncementCriteria
    ) {
      id
      title
      content
      publishAt
      expireAt
      createdAt
    }
    allEvents(filters: $eventsFilters, criteria: $eventCriteria) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
          description
          startDate
          endDate
          pinned
          slug
          cancelled
          parkId
          type {
            id
            name
          }
          category {
            id
            name
            media {
              id
              path
            }
          }
          eventTypeId
          parkId
          deletedAt
          media {
            id
            alternateText
            path
          }
        }
      }
    }
    allParkMessages(filters: $parkMessageFilters) {
      id
      message
      publishAt
      expiresAt
      createdAt
      park {
        name
      }
      type {
        id
        name
      }
      contact {
        salutation
        firstname
        surname
        jobRole {
          jobTitle
        }
        media {
          id
          path
          alternateText
        }
      }
    }
    allParkNotices(filters: $parkNoticeFilters, criteria: $parkNoticeCriteria) {
      id
      title
      description
      publishAt
      expireAt
    }
    allOwnershipSpecialOffers(filters: $specialOfferFilters, criteria: $specialOfferCriteria) {
      id
      tenantId
      title
      shortDescription
      meta
      page {
        url
      }
      startDate
      endDate
      publishAt
      expiresAt
      createdAt
      updatedAt
      deletedAt
      order
      media {
        path
      }
    }
  }
`

export default GET_DASHBOARD_DATA
