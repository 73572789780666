import {
  ContactUs,
  CustomerCare, Dashboard, Event, Events, Faqs, ForgotPassword, InformationResources, Login,
  Logout, ManagersMessage, Markdown, PasswordReset, Register, SpecialOfferRouter, SpecialOffers, VerifyAccount
} from './views'

const authRoutes = [
  {
    path: '/login',
    component: Login,
    exact: true,
    hideLayout: true,
    isPublic: true,
  },
  {
    path: '/register',
    component: Register,
    exact: true,
    hideLayout: true,
    isPublic: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
    hideLayout: true,
    isPublic: true,
  },
  {
    path: '/reset-password/:username/:code',
    component: PasswordReset,
    exact: true,
    hideLayout: true,
    isPublic: true,
  },
  {
    path: '/verify',
    component: VerifyAccount,
    hideLayout: true,
    isPublic: true,
  },
  {
    path: '/logout',
    component: Logout,
    hideLayout: true,
    isPublic: true,
  },
]

const publicRoutes = [
  {
    path: '/terms-conditions',
    component: Markdown,
    exact: true,
    props: {
      pageTitle: 'Terms and Conditions',
      markdownSlug: 'legal/master/my.parkholidays.com/Terms and Conditions',
    },
    isPublic: true,
  },
]

const privateRoutes = [
  {
    path: '/',
    component: Dashboard,
    exact: true,
  },
  {
    path: '/contact-us',
    component: ContactUs,
    exact: true,
  },
  {
    path: '/customer-care',
    component: CustomerCare,
    exact: true,
  },
  {
    path: '/frequently-asked-questions',
    component: Faqs,
    exact: true,
  },
  {
    path: '/events',
    component: Events,
    exact: true,
  },
  {
    path: '/events/:eventSlug',
    component: Event,
    exact: true,
  },
  {
    path: '/managers-message',
    component: ManagersMessage,
    exact: true,
  },
  {
    path: '/special-offers',
    component: SpecialOffers,
    exact: true,
  },
  {
    path: '/special-offers/:slug',
    component: SpecialOfferRouter,
    exact: true,
  },

  {
    path: '/information-resources',
    component: InformationResources,
    exact: true,
  },
]

export default [...authRoutes, ...publicRoutes, ...privateRoutes]
