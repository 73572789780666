import React from 'react'
import PropTypes from 'prop-types'

import Card, {
  CardHeader,
  CardContent,
  CardActions,
} from '../../../../components/ui/card'

import Icon from '../../../../components/ui/icon'
import Button from '../../../../components/ui/button'
import Typography from '../../../../components/ui/typography'

import trimText from '../../../../helpers/trimText'
import Theme from '../../../../context/theme'

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

const Notice = ({ title, description }) => {
  const { theme } = React.useContext(Theme)
  const [expanded, setExpanded] = React.useState(false)

  const maxLength = 300

  return (
    <Card
      $theme={theme}
      $elevation={5}
      $color="white"
      style={{
        marginBottom: theme.spacing(1),
        height: 'calc(100% - 8px)',
      }}
    >
      <CardHeader>
        <Typography as="h4" noMargins>
          {title}
        </Typography>
      </CardHeader>

      <CardContent>
        <Typography as="p" noMargins>
          {description.length > maxLength && !expanded
            ? trimText(description, maxLength)
            : description}
        </Typography>
      </CardContent>

      {description.length > maxLength && (
        <CardActions style={{ textAlign: 'right' }}>
          <Button
            endIcon={<Icon name={!expanded ? 'angle-down' : 'angle-up'} />}
            variant="text"
            color="corporate"
            align="center"
            onClick={() => setExpanded(!expanded)}
          >
            {!expanded ? 'READ MORE' : 'READ LESS'}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

Notice.propTypes = propTypes
export default Notice
