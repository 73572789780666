const buttonStyles = ({ theme }) => ({
  minHeight: '48px',
  minWidth: '48px',
  borderTopWidth: 0,
  borderRightWidth: 0,
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  borderStyle: 'solid',
  borderColor: theme.palette.common.gray50,
  backgroundColor: theme.palette.common.white,
  cursor: 'pointer',
  ':disabled': {
    cursor: 'not-allowed',
    backgroundColor: theme.palette.common.gray50,
  },
})

const paginationBarStyles = ({ theme, $style }) => ({
  display: 'flex',
  width: '100%',
  borderRadius: '4px',
  boxShadow: theme.lighting.shadow500,
  overflow: 'hidden',
  ...$style,
})

const prevButtonStyles = props => ({
  ...buttonStyles(props),
  borderRightWidth: '1px',
  borderLeftWidth: 0,
  borderTopLeftRadius: '4px',
})

const nextButtonStyles = props => ({
  ...buttonStyles(props),
  borderRightWidth: 0,
  borderLeftWidth: '1px',
})

const pageLabelWrapper = ({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderStyle: 'none',
  backgroundColor: theme.palette.common.white,
})

export default props =>
  Object.freeze({
    paginationBarStyles: paginationBarStyles(props),
    prevButtonStyles: prevButtonStyles(props),
    nextButtonStyles: nextButtonStyles(props),
    pageLabelWrapper: pageLabelWrapper(props),
  })
