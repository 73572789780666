import { styled } from 'styletron-react';

export const DetailedParkWrapper = styled('div', {
  boxSizing: 'border-box',
  position: 'relative',
  height: '73px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingRight: '6px',
  paddingLeft: '6px',
  cursor: 'pointer',
  borderBottom: '1px solid #e8e8e8',
  ':hover': {
    backgroundColor: '#f4f4f4',
  },
});

export const DetailedParkImage = styled('div', {
  boxSizing: 'border-box',
  position: 'relative',
  flex: '0 0 46px',
  paddingRight: '6px',
})
