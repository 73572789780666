import React from 'react'
import { useStyletron } from '@parkholidays/legacy_components'
import OwnershipButton from '@parkholidays/legacy_components/dist/button/OwnershipButton'
import { EventGrid, EventGridItem } from '../EventGrid/EventGrid'
import EventCard from '../EventCard/EventCard'
import HrTitle from '../../../../components/ui/hr-title'
import Theme from '../../../../context/theme'
import { OWNERSHIP } from 'src/constants'

const NextMonthPreview = ({ events, month, year, onHandleNextClick }) => {
  const { theme } = React.useContext(Theme)
  const { isPl2k, palette } = theme
  const [css] = useStyletron()

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return (
    <>
      <HrTitle
        color={OWNERSHIP}
        style={{ marginTop: theme.spacing(3) }}
      >
        {monthNames[month]} {year}
      </HrTitle>

      <EventGrid>
        {events.map(
          ({
            node: {
              id,
              pinned,
              type,
              cancelled,
              title,
              startDate,
              endDate,
              slug,
              category,
              media,
            },
          }) => (
            <EventGridItem key={id} xs={12} md={6} lg={4}>
              <EventCard
                isOwnersExclusive={type && type.name === 'Owners Only'}
                isPinned={pinned}
                isCancelled={cancelled}
                title={title}
                startDate={startDate}
                endDate={endDate}
                slug={slug}
                type={type.name}
                category={category.name}
                defaultCategoryMedia={category.media}
                media={media}
                contextTheme={{ isPl2k, colors: palette }}
              />
            </EventGridItem>
          ),
        )}
      </EventGrid>

      <div className={css({ display: 'flex', justifyContent: 'flex-end' })}>
        <OwnershipButton
          ariaLabel='View all monthly events'
          onClick={onHandleNextClick}
        >{`View All ${monthNames[month]} Events`}</OwnershipButton>
      </div>
    </>
  )
}

export default NextMonthPreview
