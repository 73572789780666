import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from '@parkholidays/legacy_components'
import Button from '@parkholidays/legacy_components/dist/button/Button'
import IconWithDirection from '@parkholidays/legacy_components/dist/icon/IconWithDirection'

const propTypes = {
  isPrevDisabled: PropTypes.bool.isRequired,
  onPrevClickHandler: PropTypes.func.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
  onNextClickHandler: PropTypes.func.isRequired,
}

const styles = {
  nav: {
    display: 'flex',
    marginLeft: '24px',
    marginRight: '24px',
    justifyContent: 'space-evenly',
  },
  anchorContainer: {
    marginLeft: '8px',
    marginRight: '8px',
    marginBottom: '48px',
    marginTop: '48px',
    textAlign: 'center',
  },
  anchor: {
    display: 'inline-flex',
    color: 'rgb(34, 34, 34)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundClip: 'padding-box',
    boxShadow:
      'transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    borderImage: 'initial',
    outline: '0px',
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.08)',
    transition:
      'box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s',
    ':hover': {
      backgroundColor: 'rgb(255, 255, 255)',
      color: 'rgb(0, 0, 0)',
      boxShadow:
        'transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 6px 16px',
      transform: 'scale(1.04)',
      borderColor: 'rgba(0, 0, 0, 0.08)',
    },
    ':disabled': {
      opacity: '0.5',
      cursor: 'not-allowed',
      color: 'rgb(221, 221, 221)',
      backgroundColor: 'rgb(255, 255, 255)',
      boxShadow: 'none',
      transform: 'scale(1)',
      borderColor: 'rgb(221, 221, 221)',
    },
  },
  svg: {
    display: 'block',
    height: '18px',
    width: '18px',
    overflow: 'visible',
  },
}

const Navigation = ({
  isPrevDisabled,
  onPrevClickHandler,
  isNextDisabled,
  onNextClickHandler,
}) => {
  const [css] = useStyletron()

  return (
    <nav role="navigation" aria-label="pagination" className={css(styles.nav)}>
      <div className={css(styles.anchorContainer)}>
        <Button
          aria-label="Previous page"
          ariaDisabled={isPrevDisabled}
          disabled={isPrevDisabled}
          onClick={onPrevClickHandler}
          $style={styles.anchor}
        >
          <IconWithDirection direction="left" style={styles.svg} />
        </Button>
      </div>
      <div className={css(styles.anchorContainer)}>
        <Button
          aria-label="Next page"
          ariaDisabled={isNextDisabled}
          disabled={isNextDisabled}
          onClick={onNextClickHandler}
          $style={styles.anchor}
        >
          <IconWithDirection direction="right" style={styles.svg} />
        </Button>
      </div>
    </nav>
  )
}

Navigation.propTypes = propTypes

export default Navigation
