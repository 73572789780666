import React from 'react'
import Card, { CardActions, CardContent } from '../../components/ui/card'
import Typography from '../../components/ui/typography'

import { ContentRoot } from './styled'

import PrimaryButton from '@parkholidays/legacy_components/dist/button/PrimaryButton'
import Text from '@parkholidays/legacy_components/dist/text/Text'

const MigrationNotice = () => {

  return (
  <ContentRoot>
    <Card
      color="white"
      style={{
        maxWidth: 400,
        border: '3 px solid #08375F',
        outline: '0 0 0 white',
      }}
      elevation={5}
    >
      <CardContent>
        <Typography as="h1" weight="bold" align="center">
          Migration to Park Holidays
        </Typography>
        <Typography as="p" noMargins align="center">
          Amble Links is becoming a Park Holidays park, and your owners account should now be migrated to the Park Holidays owners area.
        </Typography>
        <Typography as="p" noMargins align="center">
          All you need to do is visit https://owners.parkholidays.com and log in using the same email address and password as you currently use here. The migration will be processed automatically and you'll be logged straight in.
        </Typography>
      </CardContent>
      <CardActions style={{ paddingBottom: 16 }}>
      <PrimaryButton
              ariaLabel='go to parkholidays owners area'
              href='https://owners.parkholidays.com/'
              openInNewWindow
              $style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text color="white">Visit the Park Holidays Owners Area</Text>
            </PrimaryButton>
      </CardActions>
    </Card>
  </ContentRoot>
)}

export default MigrationNotice
