import React from 'react'

import { LinkButton } from '../../components/ui/button'
import Card, { CardActions, CardContent } from '../../components/ui/card'
import Typography from '../../components/ui/typography'

import { ContentRoot } from './styled'

import Theme from '../../context/theme'

const NoPark = () => {

  const { theme } = React.useContext(Theme);

  return (
  <ContentRoot>
    <Card
      color="white"
      style={{
        maxWidth: 320,
        border: '3 px solid #08375F',
        outline: '0 0 0 white',
      }}
      elevation={5}
    >
      <CardContent>
        {/* <OwnersLogo
          width="200"
          style={{
            display: 'block',
            margin: '16px auto 16px',
            filter: 'drop-shadow(0 0 2px black)',
          }}
        /> */}

        <Typography as="h1" weight="bold" align="center">
          Account Setup
        </Typography>

        <Typography as="p" noMargins align="center">
          Your email address cannot be associated to an active owner account.
          Please email customer care via{' '}
          { theme.isPl2k ?
          <a href="mailto:ownersloungesupport@parkleisure.co.uk">
           ownersloungesupport@parkleisure.co.uk
        </a>
        :
        <a href="mailto:onlinesupport@parkholidays.com">
          onlinesupport@parkholidays.com
        </a>
          }
          {' '}including your CUS number and we will look in to this for you.
        </Typography>
      </CardContent>
      <CardActions style={{ paddingBottom: 16 }}>
        <LinkButton color="corporateDark" to="/logout" size="small" fullWidth>
          Return to Login page
        </LinkButton>
      </CardActions>
    </Card>
  </ContentRoot>
)}

export default NoPark
